import { FC } from 'react';
import { ChecklistField } from '../../_atoms/ChecklistField';
import { ChecklistTemplate } from '../../../types/ChecklistTemplate';
import { Button } from '_atoms';
import { useTranslation } from 'react-i18next';

export const Checklist: FC<{
    checklist: ChecklistTemplate;
    isEditable?: boolean;
    showSignButton?: boolean;
    onEdit?: (fields: ChecklistTemplate['data']['fields']) => void;
    onSign?: () => void;
}> = ({
    checklist,
    isEditable = false,
    showSignButton = false,
    onEdit = () => {
        /* nop */
    },
    onSign = () => {
        /* nop */
    },
}) => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col gap-2 w-full bg-white rounded p-4 h-min">
            {checklist.data.fields.length === 0 && (
                <p>{t('checklist.empty')}</p>
            )}
            {checklist.data.fields.map((field) => (
                <ChecklistField
                    field={field}
                    key={field.name}
                    isEditable={isEditable}
                    onEdit={(editedField) => {
                        onEdit(
                            checklist.data.fields.map((f) =>
                                f.name === editedField.name ? editedField : f,
                            ),
                        );
                    }}
                />
            ))}
            {showSignButton && (
                <Button level="primary" onClick={onSign}>
                    {t('checklist.sign')}
                </Button>
            )}
        </div>
    );
};
