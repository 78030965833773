import { AllDataArticle } from 'src/services/searchService';
import { SuspicionIndicator, SuspicionItemEntity } from './SuspicionItemEntity';
import { SuspicionItemStatus } from '_enums';

export type SuspicionItem = {
    id: string;
    targetId: string;
    suspicionId: string;
    userId?: string;
    entity: SuspicionItemEntity;
    status: string;
    score: number;
    createdAt: Date;
    updatedAt: Date;
};

export type EntityBySuspicion = {
    id: string;
    value: AllDataArticle;
    createdAt: Date;
    updatedAt: Date;
    type: string;
    suspicionItemId: string;
    involvement: SuspicionItemInvolvement | null;
    indicators: Array<SuspicionIndicator>;
    score: number;
    status: string;
};

export const INVOLVEMENT_OPTIONS = [
    'perpetrator',
    'victim',
    'suspect',
] as const;

export type SuspicionItemInvolvement = typeof INVOLVEMENT_OPTIONS[number];

export enum SuspicionItemSource {
    User = 'User',
    Cortical = 'Cortical',
    LogosLLM = 'LogosLLM',
    ML = 'ML',
    OwnKeyword = 'OwnKeyword',
    KeywordList = 'KeywordList',
}

export interface CreateSuspicionItemBody {
    entity: AllDataArticle;
    suspicionIds: string[];
    targetId: string;
    involvement?: SuspicionItemInvolvement;
    status: SuspicionItemStatus;
    source: SuspicionItemSource;
}

export enum SuspicionItemInvolvementDecision {
    Inferred = 'inferred', // Involvement inferred from context
    Explicit = 'explicit', // Explicitly stated involvement
    Assumed = 'assumed', // Assumed involvement based on available information
    Unknown = 'unknown', // Involvement is unknown
}

// this is called ReportLabel in the backend
export enum SuspicionCategory {
    RedFlags = 'red_flags',
    ComplianceViolations = 'compliance_violations',
    GeneralCrime = 'general_crime',
    OwnKeywords = 'own_keywords',
    GeneralAnomalies = 'general_anomalies',
    ProblematicContentDetected = 'problematic_content_detected',
    ExtremeOpinions = 'extreme_opinions',
    Moderation = 'moderation',
}

export const suspicionCategoryMap: Partial<Record<string, SuspicionCategory>> =
    {
        asset_misuse_misdemeanors: SuspicionCategory.ComplianceViolations,
        assets_misuse_offenses: SuspicionCategory.ComplianceViolations,
        banking_payment_offenses: SuspicionCategory.ComplianceViolations,
        black_market: SuspicionCategory.ComplianceViolations,
        capital_market_offenses: SuspicionCategory.ComplianceViolations,
        copyright_infringement: SuspicionCategory.ComplianceViolations,
        corporate_governance_code: SuspicionCategory.ComplianceViolations,
        corporate_insurance_offenses: SuspicionCategory.ComplianceViolations,
        corporate_law_violations: SuspicionCategory.ComplianceViolations,
        corporate_logistics_offenses: SuspicionCategory.ComplianceViolations,
        corporate_obligations: SuspicionCategory.ComplianceViolations,
        corporate_operations: SuspicionCategory.ComplianceViolations,
        data_breach: SuspicionCategory.ComplianceViolations,
        data_security_breaches: SuspicionCategory.ComplianceViolations,
        debt_law_offenses: SuspicionCategory.ComplianceViolations,
        employment_relationships: SuspicionCategory.ComplianceViolations,
        exploiting_leading_position: SuspicionCategory.ComplianceViolations,
        fair_trading_offenses: SuspicionCategory.ComplianceViolations,
        financial_reporting: SuspicionCategory.ComplianceViolations,
        health_safety_offenses: SuspicionCategory.ComplianceViolations,
        human_rights_violations: SuspicionCategory.ComplianceViolations,
        information_protection: SuspicionCategory.ComplianceViolations,
        insolvency_law_offenses: SuspicionCategory.ComplianceViolations,
        labor_law_offenses: SuspicionCategory.ComplianceViolations,
        potential_compliance_violation: SuspicionCategory.ComplianceViolations,
        procurement_law_offenses: SuspicionCategory.ComplianceViolations,
        product_safety: SuspicionCategory.ComplianceViolations,
        property_offenses: SuspicionCategory.ComplianceViolations,
        social_security_fraud: SuspicionCategory.ComplianceViolations,
        sunsidies_funding_offenses: SuspicionCategory.ComplianceViolations,
        sustainability_environment: SuspicionCategory.ComplianceViolations,
        tax_offenses: SuspicionCategory.ComplianceViolations,
        trade_secrets: SuspicionCategory.ComplianceViolations,
        transactions: SuspicionCategory.ComplianceViolations,
        wrong_notices: SuspicionCategory.ComplianceViolations,
        climate_change_denier: SuspicionCategory.ExtremeOpinions,
        conspiracy_theorist: SuspicionCategory.ExtremeOpinions,
        corona_denier: SuspicionCategory.ExtremeOpinions,
        russian_war_supporter: SuspicionCategory.ExtremeOpinions,
        bmf_fiu_risk_country: SuspicionCategory.GeneralAnomalies,
        company_blacklist: SuspicionCategory.GeneralAnomalies,
        eu_third_country_with_increased_: SuspicionCategory.GeneralAnomalies,
        fatf_increased_monitoring: SuspicionCategory.GeneralAnomalies,
        money_laundering_country_list: SuspicionCategory.GeneralAnomalies,
        negative_sentiment: SuspicionCategory.GeneralAnomalies,
        no_internet_presence: SuspicionCategory.GeneralAnomalies,
        offshore_leak: SuspicionCategory.GeneralAnomalies,
        arson: SuspicionCategory.GeneralCrime,
        assault: SuspicionCategory.GeneralCrime,
        business_operations_and_risk_management_offenses:
            SuspicionCategory.GeneralCrime,
        capital_offenses: SuspicionCategory.GeneralCrime,
        child_pornography: SuspicionCategory.GeneralCrime,
        clans: SuspicionCategory.GeneralCrime,
        corruption: SuspicionCategory.GeneralCrime,
        cybercrime: SuspicionCategory.GeneralCrime,
        digital_black_markets: SuspicionCategory.GeneralCrime,
        exhibitionism: SuspicionCategory.GeneralCrime,
        explosives: SuspicionCategory.GeneralCrime,
        extortion: SuspicionCategory.GeneralCrime,
        extremism_terror: SuspicionCategory.GeneralCrime,
        financial_and_corporate_compliance_offenses:
            SuspicionCategory.GeneralCrime,
        firearms: SuspicionCategory.GeneralCrime,
        foreign_trade_law: SuspicionCategory.GeneralCrime,
        fraud_consumer: SuspicionCategory.GeneralCrime,
        hacking_attacks: SuspicionCategory.GeneralCrime,
        hate_speech: SuspicionCategory.GeneralCrime,
        identity_theft: SuspicionCategory.GeneralCrime,
        indications_for_crime: SuspicionCategory.GeneralCrime,
        intolerance_bigotry_discrimination: SuspicionCategory.GeneralCrime,
        islamist_terror: SuspicionCategory.GeneralCrime,
        left_wing_extremism: SuspicionCategory.GeneralCrime,
        malware: SuspicionCategory.GeneralCrime,
        money_laundering: SuspicionCategory.GeneralCrime,
        murder_manslaughter: SuspicionCategory.GeneralCrime,
        negative_content: SuspicionCategory.GeneralCrime,
        offenses: SuspicionCategory.GeneralCrime,
        organized_crime: SuspicionCategory.GeneralCrime,
        other_criminal_activities: SuspicionCategory.GeneralCrime,
        property_damage: SuspicionCategory.GeneralCrime,
        racism: SuspicionCategory.GeneralCrime,
        ransomware: SuspicionCategory.GeneralCrime,
        rape: SuspicionCategory.GeneralCrime,
        red_light_milieu: SuspicionCategory.GeneralCrime,
        right_wing_extremism: SuspicionCategory.GeneralCrime,
        robbery_kidnapping: SuspicionCategory.GeneralCrime,
        rockers: SuspicionCategory.GeneralCrime,
        scam_fraud: SuspicionCategory.GeneralCrime,
        sex_offenses: SuspicionCategory.GeneralCrime,
        sexual_assault: SuspicionCategory.GeneralCrime,
        substance_abuse: SuspicionCategory.GeneralCrime,
        theft: SuspicionCategory.GeneralCrime,
        violent_crimes: SuspicionCategory.GeneralCrime,
        weapon_offenses: SuspicionCategory.GeneralCrime,
        white_collar_crime: SuspicionCategory.GeneralCrime,
        harassment: SuspicionCategory.Moderation,
        harassment_threatening: SuspicionCategory.Moderation,
        hate: SuspicionCategory.Moderation,
        hate_threatening: SuspicionCategory.Moderation,
        self_harm: SuspicionCategory.Moderation,
        self_harm_instructions: SuspicionCategory.Moderation,
        self_harm_intent: SuspicionCategory.Moderation,
        sexual: SuspicionCategory.Moderation,
        sexual_minors: SuspicionCategory.Moderation,
        violence: SuspicionCategory.Moderation,
        violence_graphic: SuspicionCategory.Moderation,
    };
