import React from 'react';
import { Badge } from '_atoms';
import classNames from 'classnames';
import { SuspicionItemStatus } from '_enums';
import { SuspicionStatusIcon } from './SuspicionStatusIcon';
import { RedFlagIcon } from './RedFlagIcon';

interface SuspicionBadgeProps {
    name: string;
    involvement?: string;
    onClick: () => void;
    isSelected: boolean;
    total?: number;
    className?: string;
    stripedOut?: boolean;
    status?: SuspicionItemStatus;
    isRedFlag?: boolean;
    disabled?: boolean;
}

export const SuspicionBadge = (
    props: SuspicionBadgeProps,
): React.ReactElement => {
    const {
        name,
        involvement,
        onClick,
        isSelected,
        total,
        className,
        stripedOut,
        status,
        isRedFlag,
        disabled,
    } = props;

    return (
        <Badge
            background="natural"
            size="default"
            className={classNames(
                'cursor-pointer group border-2',
                isSelected ? 'border-primary-4' : 'border-transparent',
                className,
                disabled && 'pointer-events-none opacity-65',
            )}
            paddingClassName="pl-2.5 pr-2 py-1"
            onClick={onClick}
        >
            <div className="flex items-center gap-2">
                {isRedFlag && <RedFlagIcon />}
                <div className="flex items-center relative">
                    <div className="font-semibold">{name}</div>
                    {Boolean(involvement) && (
                        <div className="font-light ml-1">({involvement})</div>
                    )}
                    {Boolean(total) && !disabled && (
                        <div
                            className={classNames(
                                'text-font-light ml-1 p-1 rounded-full flex justify-center items-center min-w-5 h-5',
                                isRedFlag ? 'bg-error-1' : 'bg-primary-1',
                                isSelected && !isRedFlag && 'bg-primary-4',
                            )}
                        >
                            {total}
                        </div>
                    )}
                    {stripedOut && (
                        <div className="w-full h-[1px] bg-primary-1 absolute top-1/2 transform -translate-y-1/2" />
                    )}
                </div>
                {!!status && <SuspicionStatusIcon status={status} />}
            </div>
        </Badge>
    );
};
