import axios, { CancelToken } from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { backendBaseUrl } from '../../backendConfig.json';
import { Target } from '../../services/dataService';
import { getSignedInUser } from '../../services/authenticationService';
import { TargetCreationRequestBody } from '../../features/targets/TargetNew/wizard/target-creation-schema';

type Payload = {
    caseId: string;
    data: TargetCreationRequestBody;
    cvImages?: string[];
};

export const createTarget = async ({
    caseId,
    data,
    cvImages,
    cancelToken,
}: Payload & { cancelToken?: CancelToken }): Promise<Target> => {
    const user = await getSignedInUser();

    const payload = Object.assign({}, data, {
        currentUserId: user.attributes.sub,
        cvImages,
    });

    const response = await axios.post(
        `https://${backendBaseUrl}/api/cases/${caseId}/targets`,
        payload,
        { cancelToken },
    );
    return response.data.data;
};

export const useCreateTargetMutation = (): UseMutationResult<
    Target,
    unknown,
    Payload
> => {
    const queryClient = useQueryClient();

    return useMutation(createTarget, {
        onSuccess: () => queryClient.invalidateQueries('targets'),
    });
};
