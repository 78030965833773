import {
    Button,
    Card,
    Headline,
    TooltipContentV2,
    TooltipTriggerV2,
    TooltipV2,
} from '_atoms';
import { ArticleSuspicion, SuspicionArticle } from '_types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditModal } from './EditModal';
import { SuspicionItemStatus, SuspicionRiskLevel } from '_enums';
import classNames from 'classnames';
import { MdChevronLeft } from 'react-icons/md';
import { RiEdit2Line } from 'react-icons/ri';
import { TableEmpty } from '_organisms/Table/TableEmpty/TableEmpty';
import {
    SuspicionCategory,
    suspicionCategoryMap,
    SuspicionItemSource,
} from '../../../../../types/SuspicionItem';
import { SuspicionList } from './SuspicionList';
import { ArticleSuspicionRiskLevel } from '../../../../../types/SuspicionsByCategory';

interface ManageSuspicionsProps {
    activeSuspicionId?: string;
    collapsed?: boolean;
    onCollapse?: () => void;
    selectedArticle?: SuspicionArticle;
}

const getSuspicionRiskLevel = (
    suspicionItem: ArticleSuspicion,
): ArticleSuspicionRiskLevel => {
    if (suspicionItem.involvement === 'perpetrator') {
        return {
            riskLevel: SuspicionRiskLevel.High,
            riskBgColor: 'bg-error-1',
            riskRiskSortingValue: 3,
        };
    }

    const suspicionCategory =
        suspicionCategoryMap[suspicionItem.suspicion.label];

    if (
        suspicionItem.involvement === 'suspect' ||
        (suspicionCategory &&
            [
                SuspicionCategory.ExtremeOpinions,
                SuspicionCategory.Moderation,
            ].includes(suspicionCategory))
    ) {
        return {
            riskLevel: SuspicionRiskLevel.Medium,
            riskBgColor: 'bg-warning-1',
            riskRiskSortingValue: 2,
        };
    }

    if (
        suspicionItem.involvement === 'victim' ||
        (suspicionCategory &&
            [
                SuspicionCategory.OwnKeywords,
                SuspicionCategory.GeneralAnomalies,
            ].includes(suspicionCategory)) ||
        suspicionItem.involvement === null
    ) {
        return {
            riskLevel: SuspicionRiskLevel.Low,
            riskBgColor: 'bg-success-1',
            riskRiskSortingValue: 1,
        };
    }

    // fyi: this should in theory never happen right now
    //      since everything not in the 4 specific categories
    //      above either has involvement assigned by the system
    //      or forces the user to add involvement manually
    return {
        riskLevel: SuspicionRiskLevel.None,
        riskBgColor: 'bg-neutral-400',
        riskRiskSortingValue: 0,
    };
};

const isUserCreatedSuspicion = (suspicion: ArticleSuspicion): boolean =>
    suspicion.sources.some(
        (source) => source.source === SuspicionItemSource.User,
    );

export const ManageSuspicions = (
    props: ManageSuspicionsProps,
): React.ReactElement => {
    const {
        activeSuspicionId,
        collapsed = false,
        selectedArticle,
        onCollapse = () => {
            /* noop */
        },
    } = props;

    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    const statusesToMoveToEnd = [
        SuspicionItemStatus.WrongTarget,
        SuspicionItemStatus.NoProblem,
    ];

    const suspicions = selectedArticle?.entity.suspicionItems ?? [];

    const suspicionsWithRiskLevel = suspicions.map((suspicion) => ({
        ...suspicion,
        ...getSuspicionRiskLevel(suspicion),
    }));

    const sortedSuspicions = suspicionsWithRiskLevel.sort((a, b) => {
        const aIsInEndGroup = statusesToMoveToEnd.includes(a.status);
        const bIsInEndGroup = statusesToMoveToEnd.includes(b.status);

        if (aIsInEndGroup && !bIsInEndGroup) return 1;
        if (!aIsInEndGroup && bIsInEndGroup) return -1;

        if (a.riskLevel !== b.riskLevel) {
            return b.riskRiskSortingValue - a.riskRiskSortingValue;
        }

        return t(`suspicionKeywords.${a.suspicion.label}`, {
            defaultValue: a.suspicion.name,
        }).localeCompare(
            t(`suspicionKeywords.${b.suspicion.label}`, {
                defaultValue: b.suspicion.name,
            }),
        );
    });

    const userCreatedSuspicions = sortedSuspicions.filter((suspicion) =>
        isUserCreatedSuspicion(suspicion),
    );
    const systemSuspicions = sortedSuspicions.filter(
        (suspicion) => !isUserCreatedSuspicion(suspicion),
    );

    const editSuspicionButton = (
        <Button
            level="custom"
            icon={RiEdit2Line}
            className={classNames(
                'text-primary-4 shadow-none self-end',
                !collapsed && 'pr-0',
            )}
            onClick={() => setIsOpen(true)}
        >
            {!collapsed && t('riskAnalysis.articleMeta.editSuspicions')}
        </Button>
    );

    return (
        <>
            <Card
                className={classNames(
                    'flex flex-col border-2 rounded-lg relative min-h-14 transition',
                    collapsed ? 'p-0 items-center' : 'p-4 pb-0',
                )}
            >
                <div className="flex flex-col gap-4">
                    {collapsed ? (
                        <Button
                            level="custom"
                            icon={MdChevronLeft}
                            onClick={onCollapse}
                            className="text-neutral-500"
                        />
                    ) : (
                        <MdChevronLeft
                            className={classNames(
                                'cursor-pointer text-neutral-500 select-none ',
                                !collapsed &&
                                    'transform rotate-180 absolute top-3.5 right-3.5', // to offset for 2px invisible border which buttons have
                            )}
                            onClick={onCollapse}
                        />
                    )}
                    {!collapsed && (
                        <>
                            <Headline Level="h5">
                                {t('riskAnalysis.articleMeta.relatesTo')}
                            </Headline>
                            {sortedSuspicions?.length === 0 ? (
                                <TableEmpty
                                    className="pt-4"
                                    imageHeight={132}
                                    message={t(
                                        'riskAnalysis.notFound.headline',
                                    )}
                                />
                            ) : (
                                <>
                                    {systemSuspicions.length > 0 && (
                                        <SuspicionList
                                            sectionHeader={t(
                                                'riskAnalysis.articleMeta.systemSuspicions',
                                            )}
                                            suspicions={systemSuspicions}
                                        />
                                    )}
                                    {userCreatedSuspicions.length > 0 && (
                                        <SuspicionList
                                            sectionHeader={t(
                                                'riskAnalysis.articleMeta.userCreatedSuspicions',
                                            )}
                                            suspicions={userCreatedSuspicions}
                                        />
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
                {collapsed ? (
                    <TooltipV2 placement="right">
                        <TooltipTriggerV2>
                            {editSuspicionButton}
                        </TooltipTriggerV2>
                        <TooltipContentV2>
                            {t('riskAnalysis.articleMeta.editSuspicions')}
                        </TooltipContentV2>
                    </TooltipV2>
                ) : (
                    editSuspicionButton
                )}
            </Card>
            <EditModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                suspicions={suspicions}
                activeSuspicionId={activeSuspicionId}
                selectedArticle={selectedArticle}
            />
        </>
    );
};
