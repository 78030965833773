import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Paragraph,
    TooltipContentV2,
    TooltipTriggerV2,
    TooltipV2,
} from '_atoms';
import { ArticleSuspicionWithRiskLevel } from '_types';
import classNames from 'classnames';
import { RELEVANT_SUSPICION_STATUSES } from './constants';
import { SuspicionStatusIcon } from '../../SuspicionStatusIcon';

interface SuspicionListProps {
    sectionHeader: string;
    suspicions: ArticleSuspicionWithRiskLevel[];
}

export const SuspicionList: React.FC<SuspicionListProps> = ({
    sectionHeader,
    suspicions,
}) => {
    const { t } = useTranslation();

    return (
        <div>
            <div className="text-neutral-500 font-jost font-bold">
                {sectionHeader}
            </div>
            <div className="flex flex-col gap-4 mt-4">
                {suspicions.map(
                    ({
                        suspicion,
                        status,
                        sources,
                        involvement,
                        riskLevel,
                        riskBgColor,
                    }) => (
                        <div
                            className="flex justify-between gap-2"
                            key={suspicion.label}
                        >
                            <div className="flex-1 flex text-sm gap-2">
                                <TooltipV2
                                    openDelay={200}
                                    placement="left"
                                    disabled={sources.length === 0}
                                >
                                    <TooltipContentV2 className="max-w-72 z-20">
                                        <Paragraph size="label" color="white">
                                            {t(
                                                `riskAnalysis.suspicionRisk.${riskLevel}.title`,
                                            )}
                                        </Paragraph>
                                    </TooltipContentV2>
                                    <TooltipTriggerV2>
                                        <div
                                            className={classNames(
                                                'rounded-full w-2 h-2 my-1',
                                                riskBgColor,
                                            )}
                                        />
                                    </TooltipTriggerV2>
                                </TooltipV2>
                                <TooltipV2
                                    openDelay={200}
                                    placement="left"
                                    disabled={sources.length === 0}
                                >
                                    <TooltipContentV2 className="max-w-72 z-20">
                                        <Paragraph
                                            size="label"
                                            weight="bold"
                                            color="white"
                                        >
                                            {t('source')}
                                        </Paragraph>
                                        <Paragraph
                                            size="label"
                                            weight="normal"
                                            color="white"
                                        >
                                            {Array.from(
                                                new Set(
                                                    sources.map((source) =>
                                                        t(
                                                            `suspicionItemSource.${source.source}`,
                                                        ),
                                                    ),
                                                ),
                                            ).join(' / ')}
                                            {involvement
                                                ? `(${t(
                                                      `suspicionItemInvolvement.${involvement}`,
                                                  )})`
                                                : ''}
                                        </Paragraph>
                                    </TooltipContentV2>
                                    <TooltipTriggerV2>
                                        <span
                                            className={classNames(
                                                status &&
                                                    !RELEVANT_SUSPICION_STATUSES.includes(
                                                        status,
                                                    ) &&
                                                    'line-through',
                                            )}
                                        >
                                            {t(
                                                `suspicionKeywords.${suspicion.label}`,
                                                {
                                                    defaultValue:
                                                        suspicion.name,
                                                },
                                            )}
                                        </span>
                                    </TooltipTriggerV2>
                                </TooltipV2>
                            </div>
                            {!!status && (
                                <SuspicionStatusIcon status={status} />
                            )}
                        </div>
                    ),
                )}
            </div>
            <hr className="border-neutral-400 mt-4" />
        </div>
    );
};
