import {
    FormFieldConfig,
    FormSchema,
    FormType,
    formTypes,
    HeaderComponentProps,
} from './form.interface';
import { ContactType, SocialMediaType, Title } from '@indicium/common';
import {
    getCountrySelectionOptions,
    getNationalitySelectionOptions,
} from '../countryCodeOptions';
import i18n, { t } from 'i18next';
import { AutocompleteOption } from '../../../../components/_atoms/AutoComplete/AutoComplete';
import { europeanCharactersRegex } from '@indicium/common/src/utils';
import moment from 'moment';
import { BiBlock, BiCheckCircle } from 'react-icons/bi';
import { ElementType, FC } from 'react';
import {
    BirthInfo,
    ContactInfoEmail,
    ContactInfoPhone,
    ContactInfoWebsite,
    DynamicKeywordContent,
    Education,
    FacebookLinks,
    InstagramLinks,
    Job,
    LinkedinLinks,
    Nationality,
    Note,
    PersonalDetails,
    ResidentialInfo,
    TwitterLinks,
    XingLinks,
} from './components/FormFieldComponents';
import {
    LeftSideSectionTitle,
    RightSideSectionTitle,
} from './components/HelperComponents';
import { FaInstagram } from 'react-icons/fa6';
import { AiOutlineLinkedin } from 'react-icons/ai';
import { LuGlobe2 } from 'react-icons/lu';
import {
    TbBrandFacebook,
    TbBrandX,
    TbBriefcase,
    TbCake,
    TbCategory,
    TbFlag,
    TbMail,
    TbNote,
    TbPhone,
    TbSchool,
    TbUser,
} from 'react-icons/tb';
import { RiXingLine } from 'react-icons/ri';
import { FiGlobe } from 'react-icons/fi';
import { getRegexForSocialMedia } from './utils';

const nationalitySelectionOptions = getNationalitySelectionOptions(
    i18n.language,
);

const countrySelectionOptions = getCountrySelectionOptions(i18n.language);

const validateDate = (
    value: string,
    invalidMessage: string,
    graterThanTodayMessage: string,
): boolean | string => {
    if (!value) {
        return true;
    }
    const currentDate = moment();
    const inputDate = moment(value, ['DD.MM.YYYY', 'YYYY'], true);

    if (!inputDate.isValid()) {
        return invalidMessage;
    }

    if (!inputDate.isSameOrBefore(currentDate)) {
        console.log('inputDate', inputDate, graterThanTodayMessage);
        return graterThanTodayMessage;
    }

    return true;
};

const validateDynamicValue = (value: string): boolean => {
    return value !== '';
};

const validateDateRange = (start?: string, end?: string): boolean | string => {
    if (!start && !end) {
        return true;
    }
    const validStart = start
        ? validateDate(
              start,
              'schemaValidation.invalidDateStart',
              'schemaValidation.invalidStartDateGraterThanCurrentDate',
          )
        : true;

    if (validStart !== true) {
        return validStart;
    }

    const validEnd = end
        ? validateDate(
              end,
              'schemaValidation.invalidDateEnd',
              'schemaValidation.invalidEndDateGraterThanCurrentDate',
          )
        : true;

    if (validEnd !== true) {
        return validEnd;
    }

    if (!start && end) {
        return 'schemaValidation.invalidDateRangeEndWithoutStart';
    }

    if (end && start) {
        const startDate = moment(start, ['DD.MM.YYYY', 'YYYY'], true);
        const endDate = moment(end, ['DD.MM.YYYY', 'YYYY'], true);

        if (!startDate.isBefore(endDate)) {
            return 'schemaValidation.invalidDateRange';
        }
    }

    return true;
};

export const formTypeOptions: AutocompleteOption[] = formTypes
    // .filter((type) => type !== 'personalDetails')
    .map((type) => ({
        value: type,
        label: `targetWizard.fields.${type}.title`,
    }));

export const personalDetailsFormConfig: FormFieldConfig[] = [
    {
        key: 'firstName',
        labelTranslationKey: 'firstname',
        type: 'text',
        validators: {
            required: 'schemaValidation.required',
            pattern: {
                value: europeanCharactersRegex,
                message: 'schemaValidation.invalidCharacter',
            },
        },
    },
    {
        key: 'middleName',
        labelTranslationKey: 'middlename',
        type: 'text',
        validators: {
            pattern: {
                value: europeanCharactersRegex,
                message: 'schemaValidation.invalidCharacter',
            },
        },
    },
    {
        key: 'lastName',
        labelTranslationKey: 'lastname',
        type: 'text',
        validators: {
            required: 'schemaValidation.required',
            pattern: {
                value: europeanCharactersRegex,
                message: 'schemaValidation.invalidCharacter',
            },
        },
    },
    {
        key: 'gender',
        labelTranslationKey: 'gender',
        type: 'select',
        options: [
            { value: 'male', labelTranslationKey: 'genders.male' },
            { value: 'female', labelTranslationKey: 'genders.female' },
        ],
    },
    {
        key: 'title',
        labelTranslationKey: 'title',
        type: 'select',
        options: Object.values(Title).map((title) => ({
            id: title,
            value: title,
            labelTranslationKey: `titles.${title}`,
        })),
    },
];

// TODO: date field extra text translation
export const birthInfoFormConfig: FormFieldConfig[] = [
    {
        key: 'dateOfBirth',
        labelTranslationKey: 'dateOfBirth',
        type: 'date',
        validators: {
            validate: (value: string): boolean | string => {
                if (!value) {
                    return true;
                }
                const currentDate = moment();
                const inputDate = moment(value, ['DD.MM.YYYY', 'YYYY'], true);
                return (
                    (inputDate.isValid() &&
                        inputDate.isSameOrBefore(currentDate)) ||
                    'schemaValidation.invalidDate'
                );
            },
        },
    },
    {
        key: 'placeOfBirth',
        labelTranslationKey: 'placeOfBirth',
        type: 'text',
    },
    {
        key: 'countryOfBirth',
        labelTranslationKey: 'countryOfBirth',
        type: 'select',
        options: countrySelectionOptions.map(({ code, name }) => {
            return {
                id: code,
                value: code,
                labelTranslationKey: name,
            };
        }),
    },
];

export const residentialInfoFormConfig: FormFieldConfig[] = [
    {
        key: 'countryOfResidence',
        type: 'select',
        options: countrySelectionOptions.map(({ code, name }) => {
            return {
                id: code,
                value: code,
                labelTranslationKey: name,
            };
        }),
        validators: {
            required: 'schemaValidation.required',
        },
    },
];

export const nationalitiesFormConfig: FormFieldConfig[] = [
    {
        key: 'nationality',
        type: 'select',
        options: nationalitySelectionOptions.map(({ code, name }) => {
            return {
                id: code,
                value: code,
                labelTranslationKey: name,
            };
        }),
        validators: {
            required: 'schemaValidation.required',
        },
    },
];

const MIN_LENGTH = 5;

export const jobsFormConfig: FormFieldConfig[] = [
    {
        key: 'companyName',
        labelTranslationKey: 'jobCompanyName',
        type: 'text',
        validators: {
            required: 'schemaValidation.required',
            pattern: {
                value: /^(?!\s*$).+/, //any character except whitespace only
                message: 'schemaValidation.required',
            },
        },
    },
    {
        key: 'jobTitle',
        labelTranslationKey: 'jobTitle',
        type: 'text',
    },
    {
        key: 'isCurrentPosition',
        labelTranslationKey: 'isCurrentPosition',
        defaultValue: false,
        type: 'checkbox',
    },
    {
        key: 'jobDate',
        labelTranslationKey: 'dateRange',
        type: 'dateRange',
        start: {
            key: 'start',
            labelTranslationKey: 'dateRangeStart',
            type: 'date',
            value: '',
            validators: {
                validate: (value: string): boolean | string => {
                    return validateDate(
                        value,
                        'schemaValidation.invalidDateStart',
                        'schemaValidation.invalidStartDateGraterThanCurrentDate',
                    );
                },
            },
        },
        end: {
            key: 'end',
            labelTranslationKey: 'dateRangeEnd',
            type: 'date',
            value: '',
            validators: {
                validate: (value: string): boolean | string => {
                    return validateDate(
                        value,
                        'schemaValidation.invalidDateEnd',
                        'schemaValidation.invalidEndDateGraterThanCurrentDate',
                    );
                },
            },
        },
        dependencies: {
            end: {
                disabled: {
                    watchField: 'isCurrentPosition',
                    condition: (value) => value === true,
                },
            },
        },
        validators: {
            validate: (value: {
                start?: string;
                end?: string;
            }): boolean | string => {
                return validateDateRange(value.start, value.end);
            },
        },
    },
    {
        key: 'selfEmployed',
        //TODO: this is "Self-Employed" even in german
        labelTranslationKey: 'selfEmployed',
        defaultValue: false,
        type: 'checkbox',
    },
    {
        key: 'companyWebsite',
        labelTranslationKey: 'jobCompanyWebsite',
        type: 'url',
        validators: {
            pattern: {
                value: /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#()?&//=]*)/,
                message: 'schemaValidation.invalidUrl',
            },
        },
    },
    {
        key: 'companyVatNumber',
        labelTranslationKey: 'jobCompanyVatNumber',
        type: 'text',
        validators: {
            minLength: {
                value: MIN_LENGTH,
                message: t('schemaValidation.minimumCharacters', {
                    minLength: MIN_LENGTH,
                }),
            },
        },
    },
    {
        key: 'companyCommercialRegisterNumber',
        labelTranslationKey: 'jobCompanyCommercialRegisterNumber',
        type: 'text',
        validators: {
            minLength: {
                value: MIN_LENGTH,
                message: t('schemaValidation.minimumCharacters', {
                    minLength: MIN_LENGTH,
                }),
            },
        },
    },
    {
        key: 'companyCountry',
        labelTranslationKey: 'jobCompanyLocation',
        type: 'select',
        options: countrySelectionOptions.map(({ code, name }) => {
            return {
                id: code,
                value: code,
                labelTranslationKey: name,
            };
        }),
    },
    {
        key: 'companyCity',
        labelTranslationKey: 'jobCompanyCity',
        type: 'text',
    },
];

export const socialMediaProfilesFormConfig = (
    type: SocialMediaType,
): FormFieldConfig[] => [
    {
        key: 'link',
        // labelTranslationKey: type,
        type: 'text',
        hintMsg: `${type}Placeholder`,
        validators: {
            required: 'schemaValidation.required',
            validate: (value: string): boolean | string => {
                let decodedUrl: string;

                try {
                    decodedUrl = decodeURIComponent(value);
                } catch (err) {
                    return `schemaValidation.invalidSocialMediaUrl.${type}`;
                }

                return (
                    getRegexForSocialMedia(type).test(decodedUrl) ||
                    `schemaValidation.invalidSocialMediaUrl.${type}`
                );
            },
        },
    },
];

export const contactFormConfig = (type: ContactType): FormFieldConfig[] => [
    {
        key: type,
        // labelTranslationKey: type,
        type: type === 'email' ? 'email' : 'text',
        hintMsg: `${type}Placeholder`,
        validators: {
            required: 'schemaValidation.required',
            pattern:
                type === 'email'
                    ? {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'schemaValidation.invalidEmail',
                      }
                    : type === 'phone'
                    ? {
                          value: /^\+[0-9]{5,16}$/,
                          message: 'schemaValidation.invalidPhone',
                      }
                    : undefined,
        },
    },
];

export const educationInfoFormConfig: FormFieldConfig[] = [
    /* got some notes here:
        - 'title' was theoretically meant to be the field of study, e.g. Computer Science, Medicine, etc.
        - 'type' was theoretically meant to be the level of the degree, e.g. Bachelor, Master, PhD
        this don't really make sense imho, I'll ditch the type and keep only `title` for now
        we'll address this properly with https://indicium-glotzi.atlassian.net/browse/IOAT-2601
     */
    {
        key: 'title',
        labelTranslationKey: 'educationTitle',
        type: 'text',
        hintMsg: 'educationTitlePlaceholder',
        validators: {
            required: 'schemaValidation.required',
            pattern: {
                value: /^(?!\s*$).+/, //any character except whitespace only
                message: 'schemaValidation.required',
            },
        },
    },
    // {
    //     key: 'type',
    //     labelTranslationKey: 'educationType',
    //     type: 'text',
    // },
    {
        key: 'institutionName',
        labelTranslationKey: 'educationInstitutionName',
        type: 'text',
    },
    {
        key: 'isCurrentInstitution',
        labelTranslationKey: 'isCurrentInstitution',
        defaultValue: false,
        type: 'checkbox',
    },
    {
        key: 'date',
        labelTranslationKey: 'dateRange',
        type: 'dateRange',
        start: {
            key: 'start',
            labelTranslationKey: 'dateRangeStart',
            type: 'date',
            value: '',
            validators: {
                validate: (value: string): boolean | string => {
                    return validateDate(
                        value,
                        'schemaValidation.invalidDateStart',
                        'schemaValidation.invalidStartDateGraterThanCurrentDate',
                    );
                },
            },
        },
        end: {
            key: 'end',
            labelTranslationKey: 'dateRangeEnd',
            type: 'date',
            value: '',
            validators: {
                validate: (value: string): boolean | string => {
                    return validateDate(
                        value,
                        'schemaValidation.invalidDateEnd',
                        'schemaValidation.invalidEndDateGraterThanCurrentDate',
                    );
                },
            },
        },
        dependencies: {
            end: {
                disabled: {
                    watchField: 'isCurrentInstitution',
                    condition: (value) => Boolean(value),
                },
            },
        },
        validators: {
            validate: (value: {
                start: string;
                end: string;
            }): boolean | string => {
                return validateDateRange(value.start, value.end);
            },
        },
    },
    {
        key: 'institutionLocation',
        labelTranslationKey: 'educationInstitutionLocation',
        type: 'text',
    },
];

export const keywordsFieldFormConfig: FormFieldConfig[] = [
    {
        key: 'value',
        type: 'text',
        validators: {
            required: 'schemaValidation.required',
            pattern: {
                value: /^(?!\s*$).+/, //any character except whitespace only
                message: 'schemaValidation.required',
            },
            validate: validateDynamicValue,
        },
    },
    {
        key: 'included',
        // labelTranslationKey: 'filters.status',
        type: 'switch',
        defaultValue: true,
        options: [
            {
                labelTranslationKey: 'dynamicForm.buttons.include',
                icon: BiCheckCircle,
            },
            {
                labelTranslationKey: 'dynamicForm.buttons.exclude',
                icon: BiBlock,
            },
        ],
    },
];

export const noteFieldFormConfig: FormFieldConfig[] = [
    {
        key: 'value',
        type: 'textarea',
    },
];

// The order of this is the same order data will appear in the form list and details section
export const formConfigMap: Record<
    FormType,
    {
        formFields: FormFieldConfig[];
        parentKey: keyof FormSchema;
        isArray?: boolean;
        icon: ElementType;
        renderComponents: {
            header?: FC<HeaderComponentProps>;
            content: FC<{ data: any }>;
        };
    }
> = {
    personalDetails: {
        formFields: personalDetailsFormConfig,
        parentKey: 'personalDetails',
        icon: TbUser,
        renderComponents: {
            content: PersonalDetails,
        },
    },
    birthInfo: {
        formFields: birthInfoFormConfig,
        parentKey: 'birthInfo',
        icon: TbCake,
        renderComponents: {
            content: BirthInfo,
        },
    },
    jobs: {
        formFields: jobsFormConfig,
        parentKey: 'jobs',
        isArray: true,
        icon: TbBriefcase,
        renderComponents: {
            header: RightSideSectionTitle,
            content: Job,
        },
    },
    residentialInfo: {
        formFields: residentialInfoFormConfig,
        parentKey: 'residentialInfo',
        icon: LuGlobe2,
        renderComponents: {
            content: ResidentialInfo,
        },
    },
    nationalities: {
        formFields: nationalitiesFormConfig,
        parentKey: 'nationalities',
        isArray: true,
        icon: TbFlag,
        renderComponents: {
            content: Nationality,
        },
    },
    educationInfo: {
        formFields: educationInfoFormConfig,
        parentKey: 'educationInfo',
        isArray: true,
        icon: TbSchool,
        renderComponents: {
            header: RightSideSectionTitle,
            content: Education,
        },
    },
    contactEmails: {
        formFields: contactFormConfig(ContactType.email),
        parentKey: 'contactEmails',
        isArray: true,
        icon: TbMail,
        renderComponents: {
            content: ContactInfoEmail,
        },
    },
    contactPhones: {
        formFields: contactFormConfig(ContactType.phone),
        parentKey: 'contactPhones',
        isArray: true,
        icon: TbPhone,
        renderComponents: {
            content: ContactInfoPhone,
        },
    },
    contactWebsites: {
        formFields: contactFormConfig(ContactType.website),
        parentKey: 'contactWebsites',
        isArray: true,
        icon: FiGlobe,
        renderComponents: {
            content: ContactInfoWebsite,
        },
    },
    facebookLinks: {
        formFields: socialMediaProfilesFormConfig(SocialMediaType.facebook),
        parentKey: 'facebookLinks',
        isArray: true,
        icon: TbBrandFacebook,
        renderComponents: {
            content: FacebookLinks,
        },
    },
    instagramLinks: {
        formFields: socialMediaProfilesFormConfig(SocialMediaType.instagram),
        parentKey: 'instagramLinks',
        isArray: true,
        icon: FaInstagram,
        renderComponents: {
            content: InstagramLinks,
        },
    },
    twitterLinks: {
        formFields: socialMediaProfilesFormConfig(SocialMediaType.twitter),
        parentKey: 'twitterLinks',
        isArray: true,
        icon: TbBrandX,
        renderComponents: {
            content: TwitterLinks,
        },
    },
    linkedinLinks: {
        formFields: socialMediaProfilesFormConfig(SocialMediaType.linkedin),
        parentKey: 'linkedinLinks',
        isArray: true,
        icon: AiOutlineLinkedin,
        renderComponents: {
            content: LinkedinLinks,
        },
    },
    xingLinks: {
        formFields: socialMediaProfilesFormConfig(SocialMediaType.xing),
        parentKey: 'xingLinks',
        isArray: true,
        icon: RiXingLine,
        renderComponents: {
            content: XingLinks,
        },
    },
    keywords: {
        formFields: keywordsFieldFormConfig,
        parentKey: 'keywords',
        isArray: true,
        icon: TbCategory,
        renderComponents: {
            header: LeftSideSectionTitle,
            content: DynamicKeywordContent,
        },
    },
    note: {
        formFields: noteFieldFormConfig,
        parentKey: 'note',
        icon: TbNote,
        renderComponents: {
            header: RightSideSectionTitle,
            content: Note,
        },
    },
};
