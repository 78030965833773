import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { DynamicFormField } from './DynamicFormField';
import { DynamicFormProps, FormSchema } from './form.interface';
import { Button, Headline, Modal, Paragraph } from '_atoms';
import { useTranslation } from 'react-i18next';

export const DynamicForm: React.FC<DynamicFormProps> = ({
    fields,
    onSubmit,
    submitButtonClassName = '',
    defaultValues,
    parentKey,
    arrayIndex,
    onCancel,
}) => {
    const { t, i18n } = useTranslation();
    const [showWarning, setShowWarning] = useState(false);
    const formMethods = useForm<Partial<FormSchema>>({
        defaultValues,
        criteriaMode: 'all',
        mode: 'onChange',
    });

    const {
        handleSubmit,
        control,
        reset,
        formState: { isDirty },
        getFieldState,
    } = formMethods;

    useEffect(() => {
        reset(defaultValues);
    }, [arrayIndex, defaultValues, reset]);

    const currentFormState = getFieldState(parentKey, formMethods.formState);
    const isFormValid = !currentFormState.isDirty || currentFormState.invalid;

    const handleCancel = () => {
        if (isDirty) {
            setShowWarning(true);
            return;
        }

        if (onCancel) {
            onCancel();
        }
    };

    const handleModalEvent = (event: 'confirm' | 'cancel') => {
        if (event === 'confirm' && onCancel) {
            onCancel();
        }

        setShowWarning(false);
    };

    const handleFormSubmit = (data: Partial<FormSchema>, event: any) => {
        const submitter = event.nativeEvent.submitter;

        if (submitter.name === 'submitAndAddNew') {
            onSubmit(data, event, true);
            return;
        }
        onSubmit(data, event, false);
    };

    const hasHelpText = i18n.exists(
        `targetWizard.fields.${parentKey}.helpText`,
    );

    const fullParentKey =
        arrayIndex !== undefined ? `${parentKey}[${arrayIndex}]` : parentKey;

    return (
        <FormProvider {...formMethods}>
            <form
                onSubmit={handleSubmit(handleFormSubmit)}
                className="space-y-4 min-h-0"
            >
                <div className="flex flex-col items-center mb-8 gap-2">
                    <Headline Level={'h4'}>
                        {t(`targetWizard.fields.${parentKey}.title`)}
                    </Headline>
                    {hasHelpText && (
                        <Paragraph
                            color="light"
                            className="text-center max-w-flow-text-base px-9"
                        >
                            {t(`targetWizard.fields.${parentKey}.helpText`)}
                        </Paragraph>
                    )}
                </div>
                <div className="flex flex-col items-center justify-center gap-4">
                    {fields.map((field, index) => {
                        const fullKey = `${fullParentKey}.${field.key}`;

                        return (
                            <DynamicFormField
                                key={fullKey}
                                field={{
                                    ...field,
                                    key: fullKey,
                                    autoFocus: index === 0,
                                }}
                                control={control}
                                fullParentKey={fullParentKey}
                                arrayIndex={arrayIndex}
                            />
                        );
                    })}
                </div>
                <div className="flex justify-center gap-4">
                    {arrayIndex !== undefined && (
                        <Button
                            type="submit"
                            name="submitAndAddNew"
                            className={submitButtonClassName}
                            disabled={isFormValid}
                        >
                            {t('confirmAndAddNew')}
                        </Button>
                    )}
                </div>
                <div className="flex justify-center gap-4">
                    {onCancel && (
                        <Button
                            level="primaryGhost"
                            onClick={handleCancel}
                            type="button"
                        >
                            {t('cancel')}
                        </Button>
                    )}

                    <Button
                        type="submit"
                        name="submit"
                        className={submitButtonClassName}
                        disabled={isFormValid}
                    >
                        {t('confirm')}
                    </Button>
                </div>
            </form>

            <Modal
                isOpen={showWarning}
                title={t('targetWizard.unsavedChangesTitle')}
                hideCloseButton={true}
            >
                <div className="flex justify-end space-x-6 mt-6">
                    <Button
                        level="primaryGhost"
                        type="button"
                        onClick={() => handleModalEvent('cancel')}
                    >
                        {t('back')}
                    </Button>
                    <Button
                        type="button"
                        level="error"
                        onClick={() => handleModalEvent('confirm')}
                    >
                        {t('continue')}
                    </Button>
                </div>
            </Modal>
        </FormProvider>
    );
};
