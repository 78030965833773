import React, { useMemo } from 'react';
import Highlighter from 'react-highlight-words';
import {
    Paragraph,
    TooltipContentV2,
    TooltipTriggerV2,
    TooltipV2,
} from '_atoms';
import { HighlightChunks, IndicatorTooltip } from './types';
import { computeSearchWords, getColorClass } from './utils';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

interface HighlightProps {
    searchWords?: HighlightChunks;
    textToHighlight?: string;
    tooltips?: IndicatorTooltip;
}

export const Highlight = ({
    searchWords,
    textToHighlight,
    tooltips,
}: HighlightProps): React.ReactElement | null => {
    const { t } = useTranslation();
    const computedSearchWords = useMemo(() => {
        if (!textToHighlight) {
            return [];
        }

        return computeSearchWords(searchWords);
    }, [searchWords, textToHighlight]);

    if (!textToHighlight) {
        return null;
    }

    const filteredAndFlattenedSearchWords = computedSearchWords
        .flatMap(({ words }) => words)
        // filter out empty strings and single characters for performance reasons
        // keep regexes as they were
        .filter((word) => typeof word !== 'string' || word.length >= 2);

    return (
        <Highlighter
            searchWords={filteredAndFlattenedSearchWords}
            textToHighlight={textToHighlight}
            highlightTag={({ children, highlightIndex }) => {
                const tooltipList = tooltips?.get(children) ?? [];
                const displayTooltip = tooltips?.has(children) ?? false;

                return (
                    <TooltipV2
                        openDelay={400}
                        placement="left-start"
                        disabled={
                            !displayTooltip ||
                            tooltipList?.filter(
                                (t) => t.suspicionLabel !== null,
                            ).length === 0
                        }
                    >
                        <TooltipContentV2 className="max-w-150 z-40">
                            <Paragraph
                                size="label"
                                weight="bold"
                                color="white"
                                className="pb-3"
                            >
                                {t('suspicion')}
                            </Paragraph>
                            <ul className="list-disc pl-5">
                                {tooltipList.map((tooltip) => (
                                    <li
                                        key={tooltip.suspicionLabel}
                                        className="py-1"
                                    >
                                        <div>
                                            <Paragraph
                                                size="label"
                                                weight="medium"
                                                color="white"
                                            >
                                                {t(
                                                    `suspicionKeywords.${tooltip?.suspicionLabel}`,
                                                )}{' '}
                                            </Paragraph>
                                            <div className="flex flex-row">
                                                <Paragraph
                                                    size="label"
                                                    weight="normal"
                                                    color="light"
                                                >
                                                    {t('highlight.source')}
                                                </Paragraph>
                                                <Paragraph
                                                    size="label"
                                                    weight="normal"
                                                    color="white"
                                                >
                                                    {'\u00A0' +
                                                        t(
                                                            `suspicionItemSource.${tooltip?.source}`,
                                                        )}
                                                    {''}
                                                </Paragraph>
                                            </div>
                                            {tooltip?.involvementDecision && (
                                                <div className="flex flex-row">
                                                    <Paragraph
                                                        size="label"
                                                        weight="normal"
                                                        color="light"
                                                    >
                                                        {t(
                                                            'highlight.inferedFromTextAs',
                                                        )}
                                                    </Paragraph>
                                                    <Paragraph
                                                        size="label"
                                                        weight="normal"
                                                        color="white"
                                                    >
                                                        {'\u00A0' +
                                                            t(
                                                                `suspicionItemInvolvementDecision.${tooltip.involvementDecision}`,
                                                            )}
                                                    </Paragraph>
                                                </div>
                                            )}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </TooltipContentV2>
                        <TooltipTriggerV2>
                            <mark
                                className={classNames(
                                    getColorClass(
                                        computedSearchWords,
                                        children,
                                        highlightIndex,
                                    ),
                                    'px-1 rounded',
                                )}
                            >
                                {children}
                            </mark>
                        </TooltipTriggerV2>
                    </TooltipV2>
                );
            }}
        />
    );
};
