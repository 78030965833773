import React, { FC, ReactNode } from 'react';
import { ChecklistTemplate } from '../../../types/ChecklistTemplate';
import { Checkbox } from '../Checkbox/Checkbox';
import { useTranslation } from 'react-i18next';
import { Input } from '../Input/Input';
import { TextArea } from '../TextArea/TextArea';
import { LuGripVertical } from 'react-icons/lu';
import { RxCross2 } from 'react-icons/rx';
import { MdAdd, MdOutlineTitle } from 'react-icons/md';
import { RiParagraph } from 'react-icons/ri';
import { BsInputCursorText, BsTextLeft } from 'react-icons/bs';
import { IoIosCheckboxOutline, IoMdRadioButtonOn } from 'react-icons/io';
import { Button } from '../Button/Button';

type ChecklistTemplateFieldProps = {
    field: ChecklistTemplate['data']['fields'][0];
    onRemove?: () => void;
    onEdit?: (field: ChecklistTemplate['data']['fields'][0]) => void;
};

export const ChecklistTemplateField: FC<ChecklistTemplateFieldProps> = ({
    field,
    onRemove = () => {
        /* nop */
    },
    onEdit = () => {
        /* nop */
    },
}) => {
    const { t } = useTranslation();
    let icon: ReactNode;
    let renderable: ReactNode;
    const requiredCheckbox = (
        <Checkbox
            label={t('checklistTemplateField.required')}
            labelPosition="append"
            centered={false}
            initialIsSelected={field.required}
            onChange={(required) => onEdit({ ...field, required })}
        />
    );
    let shouldRenderRequiredCheckbox = false;
    switch (field.type) {
        case 'title':
            icon = <MdOutlineTitle />;
            renderable = (
                <Input
                    value={field.label}
                    onChange={(e) =>
                        onEdit({ ...field, label: e.target.value })
                    }
                />
            );
            break;
        case 'description':
            icon = <RiParagraph />;
            renderable = (
                <TextArea
                    value={field.label}
                    className="text-base"
                    onChange={(e) =>
                        onEdit({ ...field, label: e.target.value })
                    }
                />
            );
            break;
        case 'text':
            icon = <BsInputCursorText />;
            renderable = (
                <Input
                    value={field.label}
                    onChange={(e) =>
                        onEdit({ ...field, label: e.target.value })
                    }
                />
            );
            shouldRenderRequiredCheckbox = true;
            break;
        case 'textarea':
            icon = <BsTextLeft />;
            renderable = (
                <Input
                    value={field.label}
                    onChange={(e) =>
                        onEdit({ ...field, label: e.target.value })
                    }
                />
            );
            shouldRenderRequiredCheckbox = true;
            break;
        case 'checkbox':
            icon = <IoIosCheckboxOutline />;
            renderable = (
                <div className="flex flex-col gap-2 items-center">
                    <Input
                        label={t(`checklistTemplateField.checkbox.label`)}
                        wrapperClassName="w-full"
                        value={field.label}
                        onChange={(e) =>
                            onEdit({ ...field, label: e.target.value })
                        }
                    />
                    <div className="flex flex-col gap-1 items-center w-full">
                        <label className="w-full block text-sm font-bold font-jost text-neutral-500">
                            {t(`checklistTemplateField.checkbox.options`)}
                        </label>
                        {field.options?.map((option) => (
                            <div
                                key={option.value}
                                className="w-full flex flex-row items-center justify-between gap-2"
                            >
                                <Input
                                    wrapperClassName="w-full"
                                    value={option.label}
                                    onChange={(e) =>
                                        onEdit({
                                            ...field,
                                            options: field.options?.map((o) =>
                                                o.value === option.value
                                                    ? {
                                                          ...o,
                                                          label: e.target.value,
                                                      }
                                                    : o,
                                            ),
                                        })
                                    }
                                />
                                <RxCross2
                                    className="text-neutral-400 border-neutral-400 border rounded-md w-12 h-12 p-3 cursor-pointer hover:border-primary-4 transition-all"
                                    onClick={() =>
                                        onEdit({
                                            ...field,
                                            options: field.options?.filter(
                                                (o) => o.value !== option.value,
                                            ),
                                        })
                                    }
                                />
                            </div>
                        ))}
                    </div>
                    <Button
                        className="text-sm scale-75"
                        size="small"
                        level="primaryGhost"
                        icon={MdAdd}
                        onClick={() =>
                            onEdit({
                                ...field,
                                options: [
                                    ...(field.options ?? []),
                                    {
                                        label: t(
                                            `checklistTemplateField.checkbox.newOptionLabel`,
                                        ),
                                        value: `${field.options?.length}`,
                                    },
                                ],
                            })
                        }
                    >
                        {t(`checklistTemplateField.checkbox.addOption`)}
                    </Button>
                </div>
            );
            shouldRenderRequiredCheckbox = true;
            break;
        case 'radio':
            icon = <IoMdRadioButtonOn />;
            renderable = (
                <div className="flex flex-col gap-2 items-center">
                    <Input
                        label={t(`checklistTemplateField.radio.label`)}
                        wrapperClassName="w-full"
                        value={field.label}
                        onChange={(e) =>
                            onEdit({ ...field, label: e.target.value })
                        }
                    />
                    <div className="flex flex-col gap-1 items-center w-full">
                        <label className="w-full block text-sm font-bold font-jost text-neutral-500">
                            {t(`checklistTemplateField.radio.options`)}
                        </label>
                        {field.options?.map((option) => (
                            <div
                                key={option.value}
                                className="w-full flex flex-row items-center justify-between gap-2"
                            >
                                <Input
                                    wrapperClassName="w-full"
                                    value={option.label}
                                    onChange={(e) =>
                                        onEdit({
                                            ...field,
                                            options: field.options?.map((o) =>
                                                o.value === option.value
                                                    ? {
                                                          ...o,
                                                          label: e.target.value,
                                                      }
                                                    : o,
                                            ),
                                        })
                                    }
                                />
                                <RxCross2
                                    className="text-neutral-400 border-neutral-400 border rounded-md w-12 h-12 p-3 cursor-pointer hover:border-primary-4 transition-all"
                                    onClick={() =>
                                        onEdit({
                                            ...field,
                                            options: field.options?.filter(
                                                (o) => o.value !== option.value,
                                            ),
                                        })
                                    }
                                />
                            </div>
                        ))}
                    </div>
                    <Button
                        className="text-sm scale-75"
                        size="small"
                        level="primaryGhost"
                        icon={MdAdd}
                        onClick={() =>
                            onEdit({
                                ...field,
                                options: [
                                    ...(field.options ?? []),
                                    {
                                        label: t(
                                            `checklistTemplateField.radio.newOptionLabel`,
                                        ),
                                        value: `${field.options?.length}`,
                                    },
                                ],
                            })
                        }
                    >
                        {t(`checklistTemplateField.radio.addOption`)}
                    </Button>
                </div>
            );
            shouldRenderRequiredCheckbox = true;
            break;
        default:
            return <div>Unknown field type: {field.type}</div>;
    }
    return (
        <div className="bg-white rounded-md border border-neutral-400 flex flex-col gap-2">
            <div className="flex gap-2 border-b border-neutral-400 p-2 py-4 items-center">
                <LuGripVertical className="cursor-grab" />
                <div className="flex flex-row items-center gap-2">
                    {icon}
                    <p className="font-bold">
                        {t(`checklistTemplateField.${field.type}.title`)}
                    </p>
                </div>
                <RxCross2
                    className="cursor-pointer ml-auto text-sm"
                    onClick={onRemove}
                />
            </div>
            <div className="py-2 px-3">
                {renderable}
                {field.label.length === 0 && (
                    <div className="text-error-1 text-sm pl-1">
                        {t('checklistTemplateField.empty')}
                    </div>
                )}
                {(field.type === 'checkbox' || field.type === 'radio') &&
                    (field.options?.length ?? 0) <= 1 && (
                        <div className="text-error-1 text-sm pl-1">
                            {t('checklistTemplateField.emptyOptions')}
                        </div>
                    )}
            </div>
            {shouldRenderRequiredCheckbox && (
                <div className="border-t border-neutral-400 px-3 py-2">
                    {requiredCheckbox}
                </div>
            )}
        </div>
    );
};
