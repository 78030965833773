import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { baseURL } from '../../services/nestApiService';
import { ChecklistTemplate } from '../../types/ChecklistTemplate';

export const getChecklistTemplates = async (): Promise<ChecklistTemplate[]> => {
    return (await axios.get(`${baseURL}/checklists/templates`)).data.data;
};

export const useChecklistTemplatesQuery = ({
    onSuccess,
    onError,
}: {
    onSuccess?: (data: ChecklistTemplate[]) => void;
    onError?: () => void;
}): UseQueryResult<ChecklistTemplate[], string> => {
    return useQuery('checklistTemplates', getChecklistTemplates, {
        onSuccess,
        onError,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
    });
};
