export const stripFalsyValues = <T>(input: T): T | undefined => {
    if (Array.isArray(input)) {
        const newArray = input
            .map((value) => stripFalsyValues(value as T))
            .filter(Boolean);

        return newArray.length !== 0 ? (newArray as unknown as T) : undefined;
    }

    if (typeof input === 'string') {
        return (input.trim() as unknown as T) || undefined;
    }

    if (typeof input === 'object' && input !== null) {
        const obj: Record<string, unknown> = {};

        for (const [key, oldValue] of Object.entries(input)) {
            const newValue = stripFalsyValues(oldValue);

            if (newValue !== undefined) {
                obj[key] = newValue;
            }
        }

        if (Object.keys(obj).length === 0) {
            return undefined;
        }

        return obj as unknown as T;
    }

    return input ?? undefined;
};
