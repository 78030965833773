import React, { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import { TiDocumentText } from 'react-icons/ti';
import { ContentType } from '_molecules';

type Props = React.PropsWithChildren<{
    headline: string;
    content: ContentType[];
    setModalContent: Dispatch<{ headline: string; content: ContentType[] }>;
}>;

export const CUT_OFF = 6;

export const WithShowMoreModal = (props: Props): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <>
            {props.children}
            {props.content.length > CUT_OFF && (
                <div
                    className="text-primary-4 font-bold mt-1.5 flex items-center cursor-pointer pl-1"
                    onClick={() => {
                        props.setModalContent({
                            headline: props.headline,
                            content: props.content,
                        });
                    }}
                >
                    {t('seeXMore', { x: props.content.length - CUT_OFF })}
                    <TiDocumentText size={20} className="ml-1" />
                </div>
            )}
        </>
    );
};
