import { FC } from 'react';
import { ChecklistTemplate } from 'src/types/ChecklistTemplate';
import { Input } from '../Input/Input';
import { TextArea } from '../TextArea/TextArea';
import { RadioGroupV2 } from '../RadioGroupV2';
import { Headline } from '../Headline/Headline';
import CheckboxGroup from '../CheckboxGroup';

export const ChecklistField: FC<{
    field: ChecklistTemplate['data']['fields'][number];
    isEditable?: boolean;
    onEdit?: (field: ChecklistTemplate['data']['fields'][number]) => void;
}> = ({
    field,
    isEditable = false,
    onEdit = () => {
        /* nop */
    },
}) => {
    switch (field.type) {
        case 'title':
            return <Headline Level="h3">{field.label}</Headline>;
        case 'description':
            return <p>{field.label}</p>;
        case 'text':
            return isEditable ? (
                <Input
                    label={`${field.label}${field.required ? ' *' : ''}`}
                    value={field.value?.[0]}
                    onChange={(e) =>
                        onEdit({ ...field, value: [e.target.value] })
                    }
                />
            ) : (
                <div>
                    <div className="font-bold font-jost text-neutral-500">
                        {field.label}
                    </div>
                    <p className="font-jost text-neutral-500">{field.value}</p>
                </div>
            );
        case 'textarea':
            return isEditable ? (
                <TextArea
                    label={`${field.label}${field.required ? ' *' : ''}`}
                    value={field.value?.[0]}
                    onChange={(e) =>
                        onEdit({ ...field, value: [e.target.value] })
                    }
                />
            ) : (
                <div>
                    <div className="font-bold font-jost text-neutral-500">
                        {field.label}
                    </div>
                    <p className="font-jost text-neutral-500">{field.value}</p>
                </div>
            );
        case 'checkbox':
            return isEditable ? (
                <CheckboxGroup
                    label={`${field.label}${field.required ? ' *' : ''}`}
                    initialSelection={field.value}
                    onChange={(value) => onEdit({ ...field, value })}
                    options={field.options?.map((option) => ({
                        value: option.value,
                        label: option.label,
                    }))}
                />
            ) : (
                <div>
                    <div className="font-bold font-jost text-neutral-500">
                        {field.label}
                    </div>
                    <p className="font-jost text-neutral-500">
                        {field.options
                            ?.filter((o) => field.value?.includes(o.value))
                            ?.map((o) => o.label)
                            .join(', ')}
                    </p>
                </div>
            );
        case 'radio':
            return isEditable ? (
                <RadioGroupV2
                    label={`${field.label}${field.required ? ' *' : ''}`}
                    name={field.name}
                    initialSelection={field.value?.[0]}
                    options={field.options?.map((option) => ({
                        value: option.value,
                        label: option.label,
                    }))}
                    onChange={(value) => onEdit({ ...field, value: [value] })}
                />
            ) : (
                <div>
                    <div className="font-bold font-jost text-neutral-500">
                        {field.label}
                    </div>
                    <p className="font-jost text-neutral-500">
                        {
                            field.options?.find(
                                (o) => o.value === field.value?.at(0),
                            )?.label
                        }
                    </p>
                </div>
            );
        default:
            return <div key={field.name}>Unknown field type</div>;
    }
};
