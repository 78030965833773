import { useCallback, useState } from 'react';

export const useCache = <K, V>() => {
    const [map] = useState(new Map<K, V>());

    const set = useCallback((key: K, value: V) => map.set(key, value), [map]);

    const get = useCallback((key: K) => map.get(key), [map]);

    return {
        get,
        set,
    };
};
