import {
    DateRange,
    DateRangeConfig,
} from '../../../features/targets/TargetNew/wizard/form.interface';
import React, { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WizardInput } from '../Input/WizardInput';
import classnames from 'classnames';
import { WizardDateInput } from './WizardDatePicker';
import { useFormContext } from 'react-hook-form';

const formatDateRange = (range: DateRange): string => {
    const start = range.start ? range.start : '';
    const end = range.end ? range.end : '';
    return `${start}${start && range.end ? ' - ' : ''}${end}`;
};

interface WizardDateRangeInputProps {
    value: DateRange;
    onChange: (value: DateRange) => void;
    onBlur: () => void;
    fullParentKey: string;
    label?: string;
    errorMsg?: string;
    required?: boolean;
    disabled?: boolean;
    hintMsg?: string;
    autoFocus?: boolean;
    config?: DateRangeConfig;
}

export const WizardDateRangeInput = forwardRef<
    HTMLInputElement,
    WizardDateRangeInputProps
>((props, ref) => {
    const { t } = useTranslation();
    const {
        value,
        onChange,
        onBlur,
        label,
        errorMsg,
        required,
        disabled,
        hintMsg,
        config,
        fullParentKey,
    } = props;

    const { watch } = useFormContext();

    const [internalValue, setInternalValue] = useState<DateRange>({
        start: value.start ?? '',
        end: value.end ?? '',
    });

    const handleDateChange = (newDate: string, key: 'start' | 'end') => {
        setInternalValue((prev) => ({ ...prev, [key]: newDate }));
    };

    useEffect(() => {
        onChange(internalValue);
    }, [internalValue, onChange]);

    const { disabled: endRangeDisabled } = config?.end || {};

    const shouldDisableEndDate = endRangeDisabled?.condition(
        watch(`${fullParentKey}.${endRangeDisabled?.watchField}`),
    );

    return (
        <div className="w-full max-w-flow-text-base mx-auto relative">
            <WizardInput
                label={label}
                errorMsg={errorMsg}
                required={required}
                value={formatDateRange(value)}
                ref={ref}
                readOnly
                onClick={() => !disabled}
                disabled={disabled}
                hintMsg={hintMsg}
                className={classnames(
                    'w-full p-2 border rounded cursor-pointer hidden',
                )}
            >
                <div className="flex justify-between gap-4 w-full">
                    <WizardDateInput
                        value={internalValue.start}
                        onChange={(newDate) =>
                            handleDateChange(newDate, 'start')
                        }
                        onBlur={onBlur}
                        placeholder={t('dateRangeStart')}
                    />
                    <WizardDateInput
                        value={shouldDisableEndDate ? '' : internalValue.end}
                        onChange={(newDate) => handleDateChange(newDate, 'end')}
                        onBlur={onBlur}
                        placeholder={t('dateRangeEnd')}
                        disabled={shouldDisableEndDate}
                    />
                </div>
            </WizardInput>
        </div>
    );
});

WizardDateRangeInput.displayName = 'WizardDateRangeInput';
