import {
    Dispatch,
    FC,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
    generatePath,
    Redirect,
    useHistory,
    useParams,
} from 'react-router-dom';
import { routes } from '../../../routes';
import {
    MixedTargetCandidateGroup,
    Paging,
    TargetStatus,
    updateTargetCandidateSelection,
} from '../../../services/dataService';
import {
    useCaseQuery,
    useTargetCandidatesQuery,
    useTargetInputQuery,
    useTargetPreselectedCandidatesQuery,
} from '_queries';
import { Button, Card, LoadingSpinner, Modal, Paragraph } from '_atoms';
import { ImageGalleryModal, InfiniteScrollV2 } from '_molecules';
import { PageNotFound } from '_organisms';
import { GoogleCandidateStatus, TargetPersonSelection } from '_types';
import { TargetPersonSelectionState } from '_enums';
import { useCandidateSorting } from './useCandidateSorting';
import { StickyHeaderPageLayout } from '../../../components/Layout/StickyHeaderPageLayout';
import classnames from 'classnames';
import { StructuredCandidate } from './StructuredCandidate';
import { UnstructuredCandidate } from './UnstructuredCandidate';
import { LuPartyPopper } from 'react-icons/lu';
import { DefaultPageLayout } from '../../../components/Layout/DefaultPageLayout';
import { TableEmpty } from '_organisms/Table/TableEmpty/TableEmpty';
import { StyledLink } from '../../../components/_atoms/StyledLink';
import { FaArrowLeft } from 'react-icons/fa6';
import { ReactComponent as HelpImage } from '../../../images/help.svg';
import { ShittyListViewUnstructuredCandidate } from './ShittyListViewUnstructuredCandidate';
import { ShittyListViewStructuredCandidate } from './ShittyListViewStructuredCandidate';
import { processCandidatesInfo } from './targetCandidates.helper';
import { useSpotlightContext } from '../../../context/Spotlight';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { isNeitherNullNorUndefined } from '_utils';
import { NEGATIVE_USER_INPUT_ID, POSITIVE_USER_INPUT_ID } from './constants';

export type SingleCandidateNavigationAction = 'previous' | 'next';

enum CandidateViewState {
    Intro,
    Pending, // pending
    Decided, // decided (included + excluded)
}

export type PendingCandidateIterator = {
    element: MixedTargetCandidateGroup | undefined;
    previous: () => void;
    next: () => void;
    previousExists: boolean;
    nextExists: boolean;
    setIndex: Dispatch<SetStateAction<number>>;
};

const useIterator = (
    array: ReadonlyArray<MixedTargetCandidateGroup>,
    skippedCandidateIds: ReadonlyArray<string>,
    addSkippedCandidate: (candidate: MixedTargetCandidateGroup) => void,
): PendingCandidateIterator => {
    const startingIndex = skippedCandidateIds.length;
    const [index, setIndex] = useState(startingIndex);

    // useEffect(() => {
    //     setIndex(startingIndex);
    // }, [array.length, startingIndex]);

    const previous = useCallback(() => {
        if (index === 0) {
            return;
        }

        setIndex(index - 1);
    }, [index]);

    const next = useCallback(() => {
        // TODO: consider if there might be a better way to do this but for now we want the index to go too far,
        //       so we can check if we're outside of bounds and render a helpful message in that case
        // if (index === array.length - 1) {
        //     return;
        // }

        const current = array[index];

        if (!skippedCandidateIds.includes(current.groupId)) {
            addSkippedCandidate(current);
        }
        setIndex(index + 1);
    }, [addSkippedCandidate, array, index, skippedCandidateIds]);

    return {
        element: array[index],
        previous,
        next,
        previousExists: index !== 0,
        nextExists: index !== array.length - 1,
        setIndex,
    };
};

const helpTextComponents = {
    bold: <strong />,
    p: <p className="flex items-center mt-2" />,
    span: <span className="font-jost text-h3 font-bold text-contrast-3 mr-3" />,
    // adding this because the english translation does not add a space after the "loop" component
    space: <span className="mr-1" />,
};

export const TargetCandidates: FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { caseId, targetId } =
        useParams<{
            caseId: string;
            targetId: string;
        }>();

    const { goto } = useSpotlightContext();

    const [showHelp, setShowHelp] = useState(false);

    const [viewState, setViewState] = useState<CandidateViewState>(
        CandidateViewState.Pending,
    );

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [stateSelection, setStateSelection] =
        useState<Record<string, TargetPersonSelectionState | undefined>>();

    const [googleCandidatesSelection, setGoogleCandidatesSelection] = useState<
        Record<string, GoogleCandidateStatus[]>
    >({});

    const [isGalleryOpened, setIsGalleryOpened] = useState(false);
    const [galleryImages, setGalleryImages] = useState<string[]>([]);

    const caseQuery = useCaseQuery(caseId);
    const userInputQuery = useTargetInputQuery(caseId, targetId);

    const { data, ...targetCandidatesQuery } = useTargetCandidatesQuery(
        caseId,
        targetId,
        caseQuery.data?.option?.option ?? null,
    );

    const preselectionData = useTargetPreselectedCandidatesQuery(
        caseId,
        targetId,
    );

    const [skippedCandidates, setSkippedCandidates] = useState<
        MixedTargetCandidateGroup[]
    >([]);

    const PAGE_SIZE = 5;
    const [currentPageData, setCurrentPageData] = useState<
        Pick<Paging, 'currentPage' | 'nextPage' | 'previousPage'>
    >({
        currentPage: 1,
        nextPage: undefined,
        previousPage: undefined,
    });

    const [pagingData, setPagingData] = useState<
        Omit<Paging, 'currentPage' | 'nextPage' | 'previousPage'>
    >({
        count: 0,
        pagesCount: 0,
    });

    const [pagingIsLoading, setPagingIsLoading] = useState(false);
    const [listViewIsLoading, setListViewIsLoading] = useState(false);

    const candidates = useMemo(() => data?.candidates ?? [], [data]);
    const googleCandidates = useMemo(
        () => data?.googleCandidates ?? [],
        [data],
    );

    const hasSelection = (() => {
        const hasSelectedCandidates = Object.values(stateSelection ?? {}).some(
            (status) => status === TargetPersonSelectionState.Confirmed,
        );
        const hasSelectedGoogleCandidates = Object.values(
            googleCandidatesSelection ?? {},
        ).some((candidates) =>
            candidates.some(
                (candidate) =>
                    candidate.status === TargetPersonSelectionState.Confirmed,
            ),
        );

        return hasSelectedCandidates || hasSelectedGoogleCandidates;
    })();

    const hasTradeRegisterData = (() => {
        return candidates.some(
            (group) =>
                group.sources.includes('orbis') ||
                group.sources.includes('zefix') ||
                group.sources.includes('companyhouse'),
        );
    })();

    const hasCandidates = candidates.length > 0 || googleCandidates.length > 0;

    const preselected = useMemo(() => {
        return preselectionData.data ?? { included: [], excluded: [] };
    }, [preselectionData.data]);

    const isTargetCandidatesQuerySuccessful = targetCandidatesQuery.isSuccess;
    const noIncludedCandidatesSelected = preselected.included.length === 0;

    useEffect(() => {
        if (isTargetCandidatesQuerySuccessful && noIncludedCandidatesSelected) {
            setViewState(CandidateViewState.Pending);
        }
    }, [isTargetCandidatesQuerySuccessful, noIncludedCandidatesSelected]);

    // initial selection
    useEffect(() => {
        if (candidates.length) {
            const enrichedCandidates = candidates.map((candidate) => ({
                ...candidate,
                status:
                    candidate.pre_checked === true
                        ? TargetPersonSelectionState.Confirmed
                        : candidate.pre_checked === false
                        ? TargetPersonSelectionState.Ignored
                        : undefined,
            }));
            setStateSelection((prev) => ({
                ...prev,
                ...Object.fromEntries(
                    enrichedCandidates.map((candidate) => [
                        candidate.groupId,
                        candidate.status,
                    ]),
                ),
                ...Object.fromEntries(
                    preselected.included.map((candidate) => [
                        candidate.groupId,
                        TargetPersonSelectionState.Confirmed,
                    ]),
                ),
            }));
        }

        if (googleCandidates?.length) {
            setGoogleCandidatesSelection((prev) => ({
                ...prev,
                ...Object.fromEntries(
                    googleCandidates.map(({ groupId, candidates }) => [
                        groupId,
                        candidates.map((candidate) => {
                            const preselectedCandidate =
                                preselected.included.find((group) => {
                                    if (group.candidates) {
                                        return group.candidates.find(
                                            (c: any) => c.id === candidate.id,
                                        );
                                    }
                                });
                            return {
                                id: candidate.id,
                                status: preselectedCandidate
                                    ? TargetPersonSelectionState.Confirmed
                                    : undefined,
                                imageSelectionStatus: (
                                    candidate.googleImages || []
                                ).map(({ id }) => ({
                                    imageId: id,
                                })),
                            };
                        }),
                    ]),
                ),
            }));
        }
    }, [candidates, googleCandidates, preselected]);

    const candidateEmbeddingMap = useMemo(() => {
        const entries = (data?.candidateFingerprints ?? [])
            .flatMap((f) => Object.entries(f))
            .map(([id, fingerprint]) => {
                if (fingerprint === null) {
                    return null;
                }

                return [id, fingerprint] as const;
            })
            .filter(isNeitherNullNorUndefined);

        const map = new Map(entries);

        if (data?.userInputFingerprints.positive) {
            map.set(
                POSITIVE_USER_INPUT_ID,
                data.userInputFingerprints.positive,
            );
        }

        if (data?.userInputFingerprints.negative) {
            map.set(
                NEGATIVE_USER_INPUT_ID,
                data.userInputFingerprints.negative,
            );
        }

        return map;
    }, [data?.candidateFingerprints, data?.userInputFingerprints]);

    const sortedCandidates = useCandidateSorting({
        allCandidates: data?.allCandidates ?? [],
        candidateEmbeddingMap,
        selectedCandidates: stateSelection,
        selectedGoogleCandidates: googleCandidatesSelection,
        userInput: userInputQuery.data,
        sortByHardCriteriaScore: true,
        skippedCandidates,
    });

    const pendingCandidates = skippedCandidates.concat(
        sortedCandidates.pending,
    );

    const pendingCandidateIterator = useIterator(
        pendingCandidates,
        skippedCandidates.map((c) => c.groupId),
        (candidate) => {
            setSkippedCandidates((prev) => [...prev, candidate]);
        },
    );

    const visibleCandidates = useMemo(() => {
        return (
            sortedCandidates.decided.slice(
                0,
                currentPageData.currentPage * PAGE_SIZE,
            ) || []
        );
    }, [sortedCandidates.decided, currentPageData.currentPage]);

    useEffect(() => {
        if (
            viewState !== CandidateViewState.Pending &&
            viewState !== CandidateViewState.Intro
        ) {
            setCurrentPageData({
                currentPage: 1,
                nextPage: undefined,
                previousPage: undefined,
            });
        }
    }, [viewState]);

    useEffect(() => {
        const count = sortedCandidates.decided.length;

        if (count !== pagingData.count) {
            setPagingData({
                count,
                pagesCount: Math.ceil(count / PAGE_SIZE),
            });
        }
    }, [sortedCandidates.decided.length, pagingData.count]);

    const handleOnSubmit = useCallback(async () => {
        const transformedStateSelection: TargetPersonSelection[] = [];
        const transformedGoogleCandidatesSelection: GoogleCandidateStatus[] =
            [];

        if (stateSelection) {
            Object.entries(stateSelection).forEach(([groupId, status]) => {
                const group = candidates.find(
                    (group) => group.groupId === groupId,
                );
                if (group) {
                    group.candidateIds.forEach((id) => {
                        transformedStateSelection.push({
                            id,
                            status: status,
                        });
                    });
                }
            });
        }

        Object.entries(googleCandidatesSelection).forEach(([_, states]) => {
            if (states) {
                const confirmedCandidates = states.filter(
                    ({ status, imageSelectionStatus }) =>
                        (status &&
                            [
                                TargetPersonSelectionState.Confirmed,
                                TargetPersonSelectionState.Ignored,
                            ].includes(status)) ||
                        imageSelectionStatus.some(
                            (iss) =>
                                iss.imageStatus ===
                                TargetPersonSelectionState.Confirmed,
                        ),
                );
                transformedGoogleCandidatesSelection.push(
                    ...confirmedCandidates,
                );
            }
        });

        setIsSubmitting(true);

        try {
            const response = await updateTargetCandidateSelection(
                caseId,
                targetId,
                {
                    candidates: transformedStateSelection,
                    googleCandidates: transformedGoogleCandidatesSelection,
                },
                true,
            );

            if (response.status >= 200 && response.status <= 204) {
                history.replace(
                    generatePath(routes.targetShow.path, { caseId, targetId }),
                );
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsSubmitting(false);
        }
    }, [
        candidates,
        caseId,
        googleCandidatesSelection,
        history,
        stateSelection,
        targetId,
    ]);

    if (
        targetCandidatesQuery.isSuccess &&
        data?.status !== TargetStatus.CandidateSelectionPending
    ) {
        return (
            <Redirect
                to={generatePath(routes.targetShow.path, {
                    caseId,
                    targetId,
                })}
            />
        );
    }

    if (caseQuery.isError) {
        return <PageNotFound />;
    }

    const handlePageChange = (pageNumber: number) => {
        setPagingIsLoading(true);

        setTimeout(() => {
            setCurrentPageData({
                currentPage: pageNumber,
                nextPage:
                    pageNumber === pagingData.pagesCount
                        ? undefined
                        : pageNumber + 1,
                previousPage: pageNumber === 1 ? undefined : pageNumber - 1,
            });
            setPagingIsLoading(false);
        }, 300);
    };

    const handleImageGalleryOpen = ({ images }: { images: string[] }) => {
        setGalleryImages(images);
        setIsGalleryOpened(true);
    };

    const handleImageGalleryClose = () => {
        setIsGalleryOpened(false);
    };

    const getSingleCandidateView = () => {
        if (pendingCandidates.length === 0) {
            return (
                <div className="text-center pt-9 space-y-2">
                    <LuPartyPopper className="text-3xl" />
                    <Paragraph weight="bold">
                        {t('finishedCandidateSelection')}
                    </Paragraph>
                </div>
            );
        }

        const currentCandidate = pendingCandidateIterator.element;

        if (!currentCandidate) {
            return (
                <Card
                    className={classnames(
                        'border-2 rounded-2xl p-4 flex relative flex-grow flex-col items-center justify-center',
                    )}
                >
                    <div className="font-jost">
                        <Trans
                            i18nKey="reachedEndOfPendingCandidatesHelp"
                            components={{
                                ...helpTextComponents,
                                loop: (
                                    <span
                                        className="text-primary-4 cursor-pointer"
                                        onClick={() =>
                                            pendingCandidateIterator.setIndex(0)
                                        }
                                    />
                                ),
                            }}
                        />
                    </div>
                </Card>
            );
        }

        if ('isGoogleCandidate' in currentCandidate) {
            return (
                <UnstructuredCandidate
                    key={currentCandidate.groupId}
                    {...currentCandidate}
                    groupSelectionState={
                        googleCandidatesSelection[currentCandidate.groupId] ||
                        []
                    }
                    updateSelectionState={(groupId, state, action) => {
                        setGoogleCandidatesSelection((prev) => ({
                            ...prev,
                            [groupId]: state,
                        }));
                        setSkippedCandidates((prev) =>
                            prev.filter((c) => c.groupId !== groupId),
                        );
                        if (action === 'previous') {
                            pendingCandidateIterator.setIndex(
                                (prev) => prev - 1,
                            );
                        }
                    }}
                    iterator={pendingCandidateIterator}
                />
            );
        }

        return (
            <StructuredCandidate
                key={currentCandidate.groupId}
                {...currentCandidate}
                id={currentCandidate.groupId}
                images={currentCandidate.images ?? []}
                info={
                    // keep theoretical support for both TargetCandidateInfo data types
                    Array.isArray(currentCandidate.info)
                        ? processCandidatesInfo(
                              currentCandidate.info,
                              currentCandidate.sources,
                              t,
                          )
                        : []
                }
                status={stateSelection?.[currentCandidate.groupId]}
                onStateChange={(
                    stateChange,
                    action: SingleCandidateNavigationAction,
                ) => {
                    setStateSelection((prev) => ({
                        ...prev,
                        [stateChange.id]: stateChange.state,
                    }));
                    setSkippedCandidates((prev) =>
                        prev.filter((c) => c.groupId !== stateChange.id),
                    );
                    if (action === 'previous') {
                        pendingCandidateIterator.setIndex((prev) => prev - 1);
                    }
                }}
                onGalleryOpen={handleImageGalleryOpen}
                iterator={pendingCandidateIterator}
            />
        );
    };

    const listView = (
        <>
            {hasCandidates && !hasTradeRegisterData && (
                <Card
                    statusColor="border-gray-200"
                    className="border-2 p-5 mb-6 text-center"
                >
                    <Paragraph>{t('noTradeRegisterDataText')}</Paragraph>
                </Card>
            )}

            {visibleCandidates.length > 0 ? (
                <>
                    <div className="flex flex-col space-y-5">
                        {visibleCandidates.map((candidate) =>
                            'isGoogleCandidate' in candidate ? (
                                <ShittyListViewUnstructuredCandidate
                                    key={candidate.groupId}
                                    {...candidate}
                                    groupSelectionState={
                                        googleCandidatesSelection[
                                            candidate.groupId
                                        ] || []
                                    }
                                    updateSelectionState={(groupId, state) => {
                                        setListViewIsLoading(true);

                                        setTimeout(() => {
                                            setGoogleCandidatesSelection(
                                                (prev) => ({
                                                    ...prev,
                                                    [groupId]: state,
                                                }),
                                            );

                                            setListViewIsLoading(false);
                                        }, 150);
                                    }}
                                    iterator={pendingCandidateIterator}
                                    isListView={true}
                                />
                            ) : (
                                <ShittyListViewStructuredCandidate
                                    key={candidate.groupId}
                                    {...candidate}
                                    id={candidate.groupId}
                                    images={candidate.images ?? []}
                                    info={
                                        // keep theoretical support for both TargetCandidateInfo data types
                                        Array.isArray(candidate.info)
                                            ? processCandidatesInfo(
                                                  candidate.info,
                                                  candidate.sources ?? [],
                                                  t,
                                              )
                                            : []
                                    }
                                    status={stateSelection?.[candidate.groupId]}
                                    onStateChange={(stateChange) => {
                                        setListViewIsLoading(true);

                                        setTimeout(() => {
                                            setStateSelection((prev) => ({
                                                ...prev,
                                                [stateChange.id]:
                                                    stateChange.state,
                                            }));

                                            setListViewIsLoading(false);
                                        }, 150);
                                    }}
                                    onGalleryOpen={handleImageGalleryOpen}
                                    iterator={pendingCandidateIterator}
                                    isListView={true}
                                />
                            ),
                        )}
                    </div>
                    <div
                        className={classnames(
                            'z-8 inset-0 fixed bg-neutral-200/70 flex justify-center items-center text-neutral-400 pointer-events-none opacity-0',
                            listViewIsLoading &&
                                'opacity-100 pointer-events-auto',
                        )}
                    >
                        <LoadingSpinner />
                    </div>
                    <InfiniteScrollV2
                        paging={{
                            currentPage: currentPageData.currentPage,
                            nextPage: currentPageData.nextPage,
                            previousPage: currentPageData.previousPage,
                            count: pagingData.count,
                            pagesCount: pagingData.pagesCount,
                        }}
                        isLoading={pagingIsLoading}
                        setPageNumber={handlePageChange}
                    />
                </>
            ) : (
                <TableEmpty
                    headline={t('noResultHeadline')}
                    message={t('noResultText')}
                />
            )}
        </>
    );

    const headerSubActionItems: {
        count: number;
        countClassNames?: string;
        label: string;
        targetViewState: CandidateViewState;
    }[] = [
        {
            count: pendingCandidates.length,
            label: 'unselectedCandidates',
            targetViewState: CandidateViewState.Pending,
        },
        {
            count: sortedCandidates.decided.length,
            label: 'decidedCandidates',
            targetViewState: CandidateViewState.Decided,
        },
    ];

    const headerSubActionClassNames = {
        count: 'font-jost font-bold text-sm ml-2 px-2 py-0.5',
        label: 'text-center',
        wrapper: 'flex justify-start items-center space-x-5',
        group: 'flex flex-col items-center justify-center px-4 py-3 -mx-4 -my-3 rounded-2xl transition-colors space-y-1',
        groupActive: 'cursor-pointer hover:bg-primary-4/15',
    };

    const headerSubActions = (
        <div className={headerSubActionClassNames.wrapper}>
            {headerSubActionItems.map(
                ({ count, label, countClassNames, targetViewState }) => (
                    <SpotlightTarget
                        name={`onboardingTargetCandidates${label}`}
                        key={label}
                    >
                        <Button
                            level={
                                viewState === targetViewState
                                    ? 'dark'
                                    : 'darkGhost'
                            }
                            className={classnames(
                                'whitespace-nowrap',
                                viewState === targetViewState &&
                                    'pointer-events-none',
                            )}
                            size="small"
                            onClick={() => setViewState(targetViewState)}
                        >
                            {t(label)}
                            <span
                                className={classnames(
                                    headerSubActionClassNames.count,
                                    countClassNames,
                                    viewState === targetViewState
                                        ? 'text-font-dark bg-font-light rounded-full'
                                        : 'text-font-light bg-font-dark rounded-full',
                                )}
                            >
                                {count}
                            </span>
                        </Button>
                    </SpotlightTarget>
                ),
            )}
        </div>
    );

    if (!targetCandidatesQuery.isSuccess) {
        return (
            <>
                {!targetCandidatesQuery.isSuccess && (
                    <StickyHeaderPageLayout
                        title={t('resolveTargetCandidatesHeadline')}
                        subtitle={t('resolveTargetCandidatesSubHeadline')}
                    >
                        <LoadingSpinner />
                    </StickyHeaderPageLayout>
                )}
            </>
        );
    }

    if (viewState === CandidateViewState.Intro) {
        return (
            <DefaultPageLayout
                title={t('resolveTargetCandidatesHeadline')}
                subtitle={t('resolveTargetCandidatesSubHeadline')}
            >
                <Card className="rounded-2xl max-w-flow-text-base flex flex-col whitespace-pre-line p-7 space-y-7 mx-auto text-center">
                    <Paragraph>
                        <Trans
                            i18nKey="targetCandidatesIntro"
                            values={{
                                totalCount:
                                    candidates.length + googleCandidates.length,
                                includedCount: preselected.included.length,
                            }}
                            components={{
                                bold: <strong />,
                                highlight: (
                                    <span className="text-primary-4 text-lg leading-5" />
                                ),
                            }}
                        />
                    </Paragraph>
                    <div className="flex justify-around">
                        <Button
                            level="secondary"
                            onClick={() =>
                                setViewState(CandidateViewState.Pending)
                            }
                        >
                            {t('reviewCandidates')}
                        </Button>
                        <Button
                            disabled={!hasSelection || isSubmitting}
                            onClick={handleOnSubmit}
                            className="whitespace-nowrap"
                        >
                            {t('startAnalysis')}
                        </Button>
                    </div>
                </Card>
            </DefaultPageLayout>
        );
    }

    const LayoutComponent =
        viewState === CandidateViewState.Pending
            ? DefaultPageLayout
            : StickyHeaderPageLayout;

    return (
        <>
            <LayoutComponent
                title={t('resolveTargetCandidatesHeadline')}
                subtitle={t('resolveTargetCandidatesSubHeadline')}
                className={classnames(
                    viewState === CandidateViewState.Pending &&
                        'h-content-height max-h-content-height flex flex-col',
                )}
                headerActions={
                    <>
                        <StyledLink
                            className="flex items-center space-x-2 absolute top-0 left-0 p-4"
                            to={`/cases/${caseId}/targets/new`}
                            onClick={() =>
                                localStorage.setItem('oldTargetId', targetId)
                            }
                        >
                            <FaArrowLeft size={14} />
                            <span>{t('back')}</span>
                        </StyledLink>
                        <div className="flex justify-end items-center space-x-5">
                            <Button
                                type="button"
                                level="darkGhost"
                                className="whitespace-nowrap"
                                onClick={() => {
                                    setShowHelp((prev) => !prev);
                                }}
                            >
                                {t('doYouNeedHelp')}
                            </Button>
                            <SpotlightTarget name="onboardingTargetCandidatesRequest">
                                <Button
                                    disabled={!hasSelection || isSubmitting}
                                    type="button"
                                    level="primary"
                                    onClick={handleOnSubmit}
                                    className="whitespace-nowrap"
                                >
                                    {t('startAnalysis')}
                                </Button>
                            </SpotlightTarget>
                        </div>
                    </>
                }
                headerSubActions={headerSubActions}
            >
                {viewState === CandidateViewState.Pending
                    ? getSingleCandidateView()
                    : listView}

                <Modal
                    isOpen={showHelp}
                    title={t('resolveTargetCandidatesHeadline')}
                    className="items-center"
                    onClose={() => setShowHelp(false)}
                    maxWidth="max-w-4xl"
                >
                    <div className="flex items-center space-x-5">
                        <HelpImage className="mx-auto flex-shrink-0" />
                        <div>
                            <Trans
                                i18nKey="candidateSelectionHelp"
                                components={helpTextComponents}
                            />
                        </div>
                    </div>
                    <div className="flex flex-row gap-2">
                        <Button
                            level="secondaryGhost"
                            onClick={() => {
                                setShowHelp(false);
                                goto(15);
                            }}
                        >
                            {t('spotlight.startTutorial')}
                        </Button>
                        <Button
                            level="darkGhost"
                            onClick={() => setShowHelp(false)}
                        >
                            {t('closeButton')}
                        </Button>
                    </div>
                </Modal>
            </LayoutComponent>
            <ImageGalleryModal
                isOpen={isGalleryOpened}
                onClose={handleImageGalleryClose}
                images={galleryImages}
            />
        </>
    );
};
