import { Control, RegisterOptions } from 'react-hook-form';
import { ColorLevel, WeightLevel } from '_atoms';
import { Title } from '@indicium/common';
import { BaseSyntheticEvent, ElementType } from 'react';

export type DateRange = {
    start: string;
    end: string;
};

export type Contact = {
    email: string;
    phone: string;
    website: string;
};

export type Job = {
    jobTitle?: string;
    // TODO: should be date range
    jobDate?: DateRange;
    selfEmployed?: boolean;
    companyName: string;
    companyWebsite?: string;
    companyVatNumber?: string;
    companyCommercialRegisterNumber?: string;
    companyCountry?: string;
    companyCity?: string;
    industry?: string;
    isCurrentPosition?: boolean;
};

export type Education = {
    title?: string;
    type?: string;
    date?: DateRange;
    institutionName?: string;
    institutionLocation?: string;
    level?: string;
    isCurrentInstitution?: boolean;
};

export type SocialMediaProfile = {
    link: string;
};

export type PersonalDetailsSchema = {
    title?: Title;
    firstName: string;
    lastName: string;
    middleName?: string;
    gender?: 'male' | 'female';
};

export type BirthInfoSchema = {
    dateOfBirth?: string;
    placeOfBirth?: string;
    countryOfBirth?: string;
};

export type DynamicKeyword = {
    included: boolean;
    value: string;
};

export type Note = {
    value?: string;
};

/*
 * Form Schemas should be objects or array objects
 */
export type FormSchema = {
    personalDetails: PersonalDetailsSchema;

    birthInfo: BirthInfoSchema;

    nationalities: { nationality: string }[];

    residentialInfo: { countryOfResidence?: string };

    jobs: Job[];
    educationInfo: Education[];

    contactEmails: { email: string }[];
    contactPhones: { phone: string }[];
    contactWebsites: { website: string }[];

    facebookLinks: SocialMediaProfile[];
    instagramLinks: SocialMediaProfile[];
    linkedinLinks: SocialMediaProfile[];
    twitterLinks: SocialMediaProfile[];
    xingLinks: SocialMediaProfile[];

    keywords: DynamicKeyword[];
    note: Note;
    description?: string;
    skills?: string[];
    hobbies?: string[];
    typos?: string[];
};

export const formTypes = [
    'personalDetails',
    'birthInfo',
    'jobs',
    'educationInfo',

    'nationalities',
    'residentialInfo',

    'contactEmails',
    'contactPhones',
    'contactWebsites',

    'facebookLinks',
    'instagramLinks',
    'linkedinLinks',
    'twitterLinks',
    'xingLinks',

    'keywords',
    'note',
] as const;

/*
 * This is intentionally redundant, to control the order in which data is displayed in the CV section.
 * We can reuse `formTypes` from the same file, but we risk someone changing the order of that
 */
export const cvDisplayOrderLeftTop: Array<FormType> = [
    // 'personalDetails',
    'nationalities',
    'birthInfo',
    'residentialInfo',
    'contactEmails',
    'contactPhones',
    'contactWebsites',
    'facebookLinks',
    'instagramLinks',
    'linkedinLinks',
    'twitterLinks',
    'xingLinks',
];

export const cvDisplayOrderRight: Array<FormType> = [
    'jobs',
    'educationInfo',
    'note',
];

export const dynamicKeywordSectionKeys: Array<FormType> = ['keywords'];

export type FormType = typeof formTypes[number];

export type FormSchemaArrayKeys = {
    [K in keyof FormSchema]: FormSchema[K] extends Array<any> ? K : never;
}[keyof FormSchema];

export type FormInputType =
    | 'text'
    | 'number'
    | 'email'
    | 'password'
    | 'url'
    | 'tel'
    | 'date'
    | 'dateRange'
    | 'time'
    | 'select'
    | 'textarea'
    | 'checkbox'
    | 'switch'
    | 'radio'
    | 'file'
    | 'arrayField'
    | 'nestedField';

export type FormFieldBase = {
    key: string;
    labelTranslationKey?: string;
    type: FormInputType;
    defaultValue?: unknown;
    hintMsg?: string;
    placeholder?: string;
    validators?: RegisterOptions;
    className?: string;
    labelClassName?: string;
    inputClassName?: string;
    disabled?: boolean;
    value?: any;
    autoFocus?: boolean;
    icon?: ElementType;

    fields?: FormFieldConfig[];
};

export interface DateRangeDependencyConfig {
    watchField?: string;
    condition: <T>(value: T) => boolean;
}

export interface DateRangeConfig {
    start?: {
        disabled?: DateRangeDependencyConfig;
    };
    end?: {
        disabled?: DateRangeDependencyConfig;
    };
}

export type DateRangeFieldConfig = FormFieldBase & {
    type: 'dateRange';
    start: FormFieldConfig;
    end: FormFieldConfig;
    dependencies?: DateRangeConfig;
};

export type NestedFieldConfig = FormFieldBase & {
    type: 'nestedField';
    fields: FormFieldConfig[];
};

export type ArrayFieldConfig = FormFieldBase & {
    type: 'arrayField';
    fields: FormFieldConfig[];
};

export type SelectOption = {
    labelTranslationKey: string;
    value: string;
    icon?: ElementType;
};

export type SelectFieldConfig = FormFieldBase & {
    type: 'select';
    options: SelectOption[];
    addEmptyOption?: boolean;
    color?: ColorLevel;
    weight?: WeightLevel;
    dataTestId?: string;
    doNotTruncate?: boolean;
};

export type SwitchOption = Omit<SelectOption, 'value'>;

export type SwitchFieldConfig = FormFieldBase & {
    type: 'switch';
    options: [SwitchOption, SwitchOption];
    defaultChecked?: boolean;
};

export type CheckboxFieldConfig = FormFieldBase & {
    type: 'checkbox';
    defaultChecked?: boolean;
};

export type FormFieldConfig =
    | FormFieldBase
    | SelectFieldConfig
    | ArrayFieldConfig
    | CheckboxFieldConfig
    | DateRangeFieldConfig
    | SwitchFieldConfig
    | NestedFieldConfig;

export type DynamicFormFieldProps = {
    field: FormFieldConfig;
    fullParentKey: string;
    control?: Control;
    arrayIndex?: number;
};

export type ExtendedSubmitHandler<T> = (
    data: T,
    event?: BaseSyntheticEvent,
    addNewEntry?: boolean,
) => void | Promise<void>;

export type DynamicFormProps = {
    fields: FormFieldConfig[];
    onSubmit: ExtendedSubmitHandler<Partial<FormSchema>>;
    onCancel?: () => void;
    submitButtonClassName?: string;
    defaultValues?: Partial<FormSchema>;
    parentKey: keyof FormSchema;
    arrayIndex?: number;
};

export type HeaderComponentProps = {
    sectionKey: string;
    icon?: ElementType;
    className?: string;
};

export type ContentComponentProps = { data: any };
