import { Checkbox } from '../Checkbox/Checkbox';
import React, { forwardRef, useState, useCallback } from 'react';

type CheckboxGroupProps = {
    label?: string;
    initialSelection?: string[];
    onChange?: (value: string[]) => void;
    className?: string;
    options?: { value: string; label: string }[];
};

export const CheckboxGroup = forwardRef<HTMLDivElement, CheckboxGroupProps>(
    (
        {
            label,
            initialSelection = [],
            onChange = () => {
                /* nop */
            },
            className,
            options = [],
        },
        ref,
    ) => {
        const [value, setValue] = useState(initialSelection);

        const handleClick = useCallback(
            (optionValue: string) => {
                let newValue: string[] = [];
                if (value.includes(optionValue)) {
                    newValue = value.filter((v) => v !== optionValue);
                } else {
                    newValue = [...value, optionValue];
                }
                setValue(newValue);
                onChange(newValue);
            },
            [value, onChange],
        );

        return (
            <div ref={ref} className={className}>
                {label && (
                    <label className="text-sm font-bold font-jost text-neutral-500">
                        {label}
                    </label>
                )}
                <div className="flex flex-col gap-2 p-2">
                    {options?.map((option) => (
                        <Checkbox
                            key={option.value}
                            label={option.label}
                            onChange={() => handleClick(option.value)}
                            centered={false}
                            labelPosition="append"
                            initialIsSelected={value.includes(option.value)}
                        />
                    ))}
                </div>
            </div>
        );
    },
);

CheckboxGroup.displayName = 'CheckboxGroup';

export default CheckboxGroup;
