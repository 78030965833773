import {
    differenceInDays,
    differenceInMonths,
    isBefore,
    isValid,
    parseISO,
    startOfDay,
} from 'date-fns';
import { Experience, StatisticConfig, Units } from '../types';
import {
    TargetReportAttribute,
    TargetReportSourceType,
} from '@indicium/common/src/types/Report/SharedTypes';
import { EntrepreneurialActivities } from '_types';
import { ProfileDataEducation } from '../../../../../services/dataService';
import { t } from 'i18next';

export type ExperienceDateRange = Record<'start' | 'end', string>;

export type WorkExperience = {
    companyName: string;
    role: string;
} & ExperienceDateRange;

const _differenceInMonths = (earlierDate: Date, laterDate: Date): number => {
    // Ensure earlier date is actually the earlier date
    if (earlierDate > laterDate) {
        [earlierDate, laterDate] = [laterDate, earlierDate];
    }

    const totalDays = differenceInDays(laterDate, earlierDate);
    const months = totalDays / 30.44; // Average number of days in a month

    return parseFloat(months.toFixed(2));
};

export const parseExperienceDateRanges = (
    dateRanges: ExperienceDateRange[],
    gapThresholdInMonths = 1,
): {
    gaps: ExperienceDateRange[];
    deduplicatedDateRanges: ExperienceDateRange[];
} => {
    const sortedEvents = dateRanges
        .flatMap((range) => [
            { type: 'start', date: range.start },
            { type: 'end', date: range.end },
        ])
        .sort(
            (a, b) => parseISO(a.date).getTime() - parseISO(b.date).getTime(),
        );

    const gaps: ExperienceDateRange[] = [];
    const deduplicatedDateRanges: ExperienceDateRange[] = [];
    let currentActiveDateRangeCount = 0;
    let currentGapStart: string | null = null;
    let currentDateRangeStart: string | null = null;

    const addNewGap = (start: string, end: string) => {
        const monthsDifference = differenceInMonths(
            parseISO(end),
            parseISO(start),
        );

        if (monthsDifference >= gapThresholdInMonths) {
            gaps.push({
                start,
                end,
            });
        }

        currentGapStart = null;
    };

    const addNewDateRange = (start: string, end: string) => {
        deduplicatedDateRanges.push({
            start,
            end,
        });

        currentDateRangeStart = null;
    };

    for (const event of sortedEvents) {
        if (event.type === 'start') {
            if (currentActiveDateRangeCount === 0) {
                if (currentDateRangeStart === null) {
                    currentDateRangeStart = event.date;
                }
                if (currentGapStart !== null) {
                    addNewGap(currentGapStart, event.date);
                }
            }
            currentActiveDateRangeCount++;
            continue;
        }

        // event.type === 'end'
        currentActiveDateRangeCount--;
        if (currentActiveDateRangeCount === 0) {
            if (currentGapStart === null) {
                currentGapStart = event.date;
            }
            if (currentDateRangeStart !== null) {
                addNewDateRange(currentDateRangeStart, event.date);
            }
        }
    }

    return {
        gaps,
        deduplicatedDateRanges,
    };
};

export const calculateTotalTimeSpanInMonths = (
    dateRanges: ExperienceDateRange[],
): number =>
    Math.round(
        dateRanges.reduce(
            (total, range) =>
                total +
                _differenceInMonths(parseISO(range.end), parseISO(range.start)),
            0,
        ),
    );

type TimespanOfPosition = {
    position: string;
    timespanInMonths: number;
};

export const getAverageTimespanInMonthsOfPositions = (
    workExperiences: WorkExperience[],
): TimespanOfPosition[] => {
    const groupedRoles = new Map<string, WorkExperience[]>();

    workExperiences.forEach((workExperience) => {
        const existingRoleGroup = groupedRoles.get(workExperience.role) ?? [];

        groupedRoles.set(workExperience.role, [
            ...existingRoleGroup,
            workExperience,
        ]);
    });

    return Array.from(groupedRoles.entries()).map(([role, experiences]) => {
        const { deduplicatedDateRanges } =
            parseExperienceDateRanges(experiences);
        return {
            position: role,
            timespanInMonths: Math.round(
                calculateTotalTimeSpanInMonths(deduplicatedDateRanges),
            ),
        };
    });
};

const getValueAndUnit = (value: number): [number, Units] =>
    value > 11
        ? [parseFloat((value / 12).toFixed(1)), 'years']
        : [value, 'months'];

type ChartConfigInput = {
    activeSources: TargetReportSourceType[];
    targetCreationDate?: string;
    entrepreneurialActivities?: EntrepreneurialActivities;
    educations?: ProfileDataEducation[];
    typos?: TargetReportAttribute<string>[];
};

export const buildChartConfig = (config: ChartConfigInput): StatisticConfig => {
    const {
        activeSources,
        targetCreationDate,
        entrepreneurialActivities,
        educations,
        typos = [],
    } = config;

    const typosBySource = activeSources.includes('userInput')
        ? typos.map(({ value }) => value)
        : [];

    const workExperienceBySource = Object.entries(
        entrepreneurialActivities ?? {},
    )
        .filter(([source]) =>
            activeSources.includes(source as TargetReportSourceType),
        )
        .flatMap(([, workExperience]) => workExperience);

    const educationBySource =
        educations?.filter((education) =>
            education.sources.some((source) => activeSources.includes(source)),
        ) ?? [];

    const workExperience = workExperienceBySource
        .map((workExperience) => ({
            companyName: workExperience.companyName,
            role: workExperience.role ?? 'unknown',
            start: workExperience.from,
            end: workExperience.to ?? targetCreationDate,
        }))
        .filter(filterExperience);

    const education = educationBySource
        .map((education) => ({
            start: education.value.date_range?.start,
            end: education.value.date_range?.end ?? targetCreationDate,
        }))
        .filter(filterExperience);

    const missingWorkRanges =
        workExperience.length !== workExperienceBySource.length;

    const missingEducationRanges =
        education.length !== educationBySource.length;

    const uniqueCompanies = Array.from(
        new Set(workExperienceBySource.flatMap((work) => work.companyName)),
    );

    const averageTimespanInMonthsOfPosition =
        getAverageTimespanInMonthsOfPositions(workExperience);

    const { gaps } = parseExperienceDateRanges([
        ...workExperience,
        ...education,
    ]);

    const { deduplicatedDateRanges: uniqueWorkExperienceRanges } =
        parseExperienceDateRanges(workExperience);

    const { deduplicatedDateRanges: uniqueEducationRanges } =
        parseExperienceDateRanges(education);

    const workExperienceTimespan = calculateTotalTimeSpanInMonths(
        uniqueWorkExperienceRanges,
    );

    const educationTimespan = calculateTotalTimeSpanInMonths(
        uniqueEducationRanges,
    );

    const [educationTimespanValue, educationTimespanUnit] =
        getValueAndUnit(educationTimespan);

    const [workExperienceTimespanValue, workExperienceTimespanUnit] =
        getValueAndUnit(workExperienceTimespan);

    return [
        {
            // educationTimespan
            componentType: 'numerical',
            labels: {
                title: 'cVAnalysis.chartTitle.educationTimespan',
                warningMessage: 'cVAnalysis.chartWarning.educationTimespan',
                missingDataMessage: 'cVAnalysis.chartEmpty.educationTimespan',
                notFoundMessage: 'cVAnalysis.chartNotFound.educationTimespan',
            },
            data: {
                showWarning: missingEducationRanges,
                isMissingData: educationTimespan === 0,
                content: education.map(
                    ({ start, end }) =>
                        `${calculateTotalTimeSpanInMonths([
                            { start, end },
                        ])} ${t('cVAnalysis.chartUnit.months')}`,
                ),
                value: educationTimespanValue,
                unit: educationTimespanUnit,
            },
        },
        {
            // workTimespan
            componentType: 'numerical',
            labels: {
                title: 'cVAnalysis.chartTitle.jobTimespan',
                warningMessage: 'cVAnalysis.chartWarning.jobTimespan',
                missingDataMessage: 'cVAnalysis.chartEmpty.jobTimespan',
                notFoundMessage: 'cVAnalysis.chartNotFound.jobTimespan',
            },
            data: {
                showWarning: missingWorkRanges,
                isMissingData: workExperienceTimespan === 0,
                content: workExperience.map(
                    ({ start, end }) =>
                        `${calculateTotalTimeSpanInMonths([
                            { start, end },
                        ])} ${t('cVAnalysis.chartUnit.months')}`,
                ),
                value: workExperienceTimespanValue,
                unit: workExperienceTimespanUnit,
            },
        },
        {
            // uniqueCompanies
            componentType: 'numerical',
            labels: {
                title: 'cVAnalysis.chartTitle.uniqueCompanies',
                warningMessage: 'cVAnalysis.chartWarning.jobTimespan',
                missingDataMessage: 'cVAnalysis.chartEmpty.jobTimespan',
                notFoundMessage: 'cVAnalysis.chartNotFound.uniqueCompanies',
            },
            data: {
                showWarning: workExperienceTimespan === 0,
                isMissingData: uniqueCompanies.length === 0,
                content: uniqueCompanies,
                value: uniqueCompanies.length,
            },
        },
        {
            // gaps
            componentType: 'numerical',
            labels: {
                title: 'cVAnalysis.chartTitle.gaps',
                warningMessage: 'cVAnalysis.chartWarning.gaps',
                missingDataMessage: 'cVAnalysis.chartEmpty.gaps',
                notFoundMessage: 'cVAnalysis.chartNotFound.gaps',
            },
            data: {
                showWarning: missingEducationRanges || missingWorkRanges,
                isMissingData:
                    educationTimespan === 0 && workExperienceTimespan === 0,
                content: gaps.map(({ start, end }) => {
                    const [value, unit] = getValueAndUnit(
                        calculateTotalTimeSpanInMonths([{ start, end }]),
                    );
                    return `${value} ${t(`cVAnalysis.chartUnit.${unit}`)}`;
                }),
                value: gaps.length,
            },
        },
        {
            // averageTimespanOfPositions
            componentType: 'donutChart',
            labels: {
                title: 'cVAnalysis.chartTitle.averageTimespanOfPositions',
                warningMessage: 'cVAnalysis.chartWarning.jobTimespan',
                missingDataMessage: 'cVAnalysis.chartEmpty.jobTimespan',
                notFoundMessage:
                    'cVAnalysis.chartNotFound.averageTimespanOfPositions',
            },
            data: {
                showWarning: missingWorkRanges,
                isMissingData: workExperienceTimespan === 0,
                data: averageTimespanInMonthsOfPosition.map(
                    ({ position, timespanInMonths }) => ({
                        name: position,
                        value: timespanInMonths,
                    }),
                ),
                unit: 'months',
                content: averageTimespanInMonthsOfPosition.map(
                    (p) =>
                        `${p.position} (${p.timespanInMonths}${t(
                            'cVAnalysis.chartUnit.months',
                        ).charAt(0)})`,
                ),
            },
        },
        {
            componentType: 'numerical',
            labels: {
                title: 'cVAnalysis.chartTitle.typos',
                warningMessage: 'cVAnalysis.chartWarning.typos',
                missingDataMessage: 'cVAnalysis.chartEmpty.typos',
                notFoundMessage: 'cVAnalysis.chartNotFound.typos',
            },
            data: {
                showWarning: typosBySource.length !== typos.length,
                isMissingData: typos.length === 0,
                content: typosBySource,
                value: typosBySource.length,
                unit: typosBySource.length === 1 ? 'typo' : 'typos',
            },
        },
    ];
};

export const ENTITIES_THRESHOLD = 10;

// TODO: We should not be generating the colors separately here, but the color should be part of the data.
// Do not change any values until the data structure is updated.
export const generateColors = (length: number): string[] => {
    const baseHue = 160;
    const baseSaturation = 50;
    const baseLightness = 80;
    const lightnessStep =
        length < ENTITIES_THRESHOLD
            ? baseLightness / length
            : ENTITIES_THRESHOLD;

    let lightness = baseLightness;
    let saturation = baseSaturation;
    let hue = baseHue;

    const colors = [];

    for (let i = 0; i < length; i++) {
        colors.push(`hsl(${hue}, ${saturation}%, ${lightness}%)`);

        lightness -= lightnessStep;
        if (lightness < 21) {
            lightness = baseLightness;

            saturation = saturation + 30;
            hue = hue + 30;
        }
    }

    return colors;
};

export const filterExperience = <T extends { start?: string; end?: string }>(
    experience: T,
): experience is T & { start: string; end: string } => {
    if (!experience.start || !experience.end) {
        return false;
    }

    const parsedStart = parseISO(experience.start);
    const parsedEnd = parseISO(experience.end);

    if (!isValid(parsedStart) || !isValid(parsedEnd)) {
        return false;
    }

    return isBefore(parsedStart, parsedEnd);
};

export const parseToDate = (
    date: string | Date | undefined | null,
): Date | null => {
    if (!date) {
        return null;
    }

    if (date instanceof Date) {
        return date;
    }

    const parsedDate = startOfDay(parseISO(date));

    return isValid(parsedDate) ? parsedDate : null;
};

export const deduplicateExperiences = (
    experiences: Experience[],
): Experience[] => {
    // if the header and subHeader are the same, deduplicate based on the start and end dates matching
    return experiences.reduce<Experience[]>((acc, experience) => {
        const existingExperience = acc.find(
            (e) =>
                e.header === experience.header &&
                e.subHeader === experience.subHeader,
        );

        if (
            existingExperience &&
            existingExperience.start.getTime() === experience.start.getTime() &&
            existingExperience.end.getTime() === experience.end.getTime()
        ) {
            return acc;
        }

        return [...acc, experience];
    }, []);
};
