import * as Yup from 'yup';
import {
    DateRange as FormSchemaDateRange,
    DynamicKeyword,
    FormSchema,
} from './form.interface';
import {
    ContactType,
    DateRange,
    DateStr,
    DynamicFormValue,
    facebookIdRegExp,
    instagramIdRegExp,
    linkedinIdRegExp,
    SocialMediaType,
    Title,
    toDateStr,
    twitterIdRegExp,
    UserInputTarget,
    xingIdRegExp,
} from '@indicium/common';
import { uniqueId } from 'lodash';
import { ProcessTarget } from '@indicium/common/src/types/Target/TargetTypes';
import moment, { unitOfTime } from 'moment/moment';
import { DynamicFormValueStatus } from '@indicium/common/src/types/DynamicForm/DynamicForm';
import { TargetFormSchema } from '../../../../schemas/targetFormSchema';
import { TargetCreationRequestBody } from './target-creation-schema';

const DATE_FORMATS = ['DD.MM.YYYY', 'YYYY'];

const validateDate = (dateString: string) => {
    const date = moment(dateString, DATE_FORMATS, false);
    if (!date.isValid()) {
        return '';
    }
    const timeUnit: unitOfTime.StartOf =
        dateString.length === 4 ? 'year' : 'day';

    if (timeUnit === 'year') {
        return dateString;
    }

    return date.format('DD.MM.YYYY');
};

export const extractDateRangeObjForFormSchema = (
    dateRange: DateRange | string | undefined,
): FormSchemaDateRange => {
    try {
        const result: FormSchemaDateRange = {
            start: '',
            end: '',
        };
        if (typeof dateRange === 'object') {
            result.start = validateDate(dateRange.start);
            result.end = validateDate(dateRange.end);
            return result;
        }
        if (typeof dateRange === 'string' && dateRange) {
            const dates = dateRange.split('-').map((date) => date.trim());
            result.start = validateDate(dates[0]);
            if (dates.length === 2) {
                result.end = validateDate(dates[1]);
            }
        }
        return result;
    } catch (error) {
        console.log('Error while extracting dates', error);
        return {
            start: '',
            end: '',
        };
    }
};

const getDateOfBirth = (
    dateOfBirth: string | DateRange | undefined,
): string => {
    if (dateOfBirth === undefined) {
        return '';
    }
    if (typeof dateOfBirth === 'string') {
        return dateOfBirth;
    }
    if (typeof dateOfBirth === 'object') {
        return dateOfBirth.start ?? '';
    }
    return '';
};

const getValidEntries = <T>(
    entries: T[],
    schema?: Yup.ObjectSchema<any>,
): T[] => {
    if (!schema) {
        return entries;
    }
    const validEntries: T[] = [];
    for (const entry of entries) {
        try {
            schema.validateSync(entry);
            validEntries.push(entry);
        } catch (err) {
            console.error('Validation error:', err, 'Invalid entry:', entry);
        }
    }
    return validEntries;
};

const getValidEntry = <T>(
    entry: T,
    schema: Yup.StringSchema<any> | undefined,
): T | undefined => {
    if (!schema) {
        return entry;
    }
    try {
        schema.validateSync(entry);
        return entry;
    } catch (err) {
        console.error('Validation error:', err, 'Invalid entry:', entry);
        return undefined;
    }
};

const mapUserInputPersonTitleToFormSchema = (
    title?: string,
): Title | undefined => {
    if (!title) {
        return undefined;
    }

    const titleEnumValues = Object.values(Title) as string[];

    if (!titleEnumValues.includes(title)) {
        return undefined;
    }

    return title as Title;
};

// TODO: confirm images are redundant when not coming from PDF upload
export const mapUserInputTargetToFormSchema = (
    input: ProcessTarget,
): FormSchema => {
    const formValues: FormSchema = {
        personalDetails: {
            firstName: input.firstname,
            lastName: input.lastname,
            middleName: input.middlename,
            title: mapUserInputPersonTitleToFormSchema(input.title),
            gender: input.gender,
        },
        birthInfo: {
            dateOfBirth: '',
            placeOfBirth: input.placeOfBirth,
            countryOfBirth: input.countryOfBirth,
        },

        residentialInfo: { countryOfResidence: input.countryOfResidence },
        nationalities: (input.nationalities ?? [])
            ?.filter(Boolean)
            .map((item) => ({ nationality: item })),
        contactEmails: [],
        contactPhones: [],
        contactWebsites: [],
        jobs: [],
        educationInfo: [],

        facebookLinks: [],
        instagramLinks: [],
        linkedinLinks: [],
        twitterLinks: [],
        xingLinks: [],
        keywords: [
            ...(input.websites ?? []),
            ...(input.relatedPersons ?? []),
            ...(input.organizations ?? []),
            ...(input.topics ?? []),
            ...(input.locations ?? []),
            ...(input.nicknames ?? []),
        ].map((keyword) => ({
            id: keyword.id,
            value: keyword.value,
            included: keyword.status === DynamicFormValueStatus.Confirmed,
        })),
        note: { value: input.note?.value },
        description: getValidEntry(input.description, Yup.string().optional()),
        skills: (input.skills ?? []).filter((skill) =>
            getValidEntry(skill, Yup.string().optional()),
        ),
        hobbies: (input.hobbies ?? []).filter((hobby) =>
            getValidEntry(hobby, Yup.string().optional()),
        ),
        typos: (input.typos ?? []).filter((typo) =>
            getValidEntry(typo, Yup.string().optional()),
        ),
    };

    if (input.dateOfBirth) {
        const dateOfBirthSchema = TargetFormSchema.fields.dateOfBirth;
        formValues.birthInfo.dateOfBirth = getValidEntry(
            getDateOfBirth(input.dateOfBirth),
            dateOfBirthSchema,
        );
    }

    // Map contact
    const contactSchema = TargetFormSchema.fields.contact.innerType;
    const validContacts = getValidEntries(
        input.contact?.filter(({ value }) => Boolean(value.trim())) ?? [],
        contactSchema,
    );
    validContacts.forEach((contact) => {
        switch (contact.type) {
            case ContactType.email:
                formValues.contactEmails.push({ email: contact.value });
                break;
            case ContactType.phone:
                formValues.contactPhones.push({ phone: contact.value });
                break;
            case ContactType.website:
                formValues.contactWebsites.push({
                    website: contact.value,
                });
                break;
        }
    });

    const jobsSchema = TargetFormSchema.fields.jobs.innerType;
    const validJobs = getValidEntries(input.jobs ?? [], jobsSchema);
    formValues.jobs = validJobs.map((job) => ({
        jobTitle: job.title,
        jobDate: extractDateRangeObjForFormSchema(job.date),
        selfEmployed: job.selfEmployed,
        companyName: job.company?.name || '',
        companyWebsite: job.company?.website,
        companyVatNumber: job.company?.vatNumber,
        companyCommercialRegisterNumber: job.company?.commercialRegisterNumber,
        companyCity: job.company?.city,
        industry: job.industry,
    }));

    // Map education
    const educationSchema = TargetFormSchema.fields.education.innerType;
    const validEducationEntries = getValidEntries(
        input.education ?? [],
        educationSchema,
    );
    formValues.educationInfo = validEducationEntries.map((edu) => ({
        title: edu.title,
        type: edu.type,
        date: extractDateRangeObjForFormSchema(edu.date),
        institutionName: edu.institution?.name,
        institutionLocation: edu.institution?.location,
        level: edu.level,
    }));

    (input.socialMediaProfiles ?? [])
        .filter(({ value }) => Boolean(value.trim()))
        .forEach((profile) => {
            if (Object.values(SocialMediaType).includes(profile.type)) {
                switch (profile.type) {
                    case SocialMediaType.facebook:
                        formValues.facebookLinks.push({ link: profile.value });
                        break;
                    case SocialMediaType.instagram:
                        formValues.instagramLinks.push({
                            link: profile.value,
                        });
                        break;
                    case SocialMediaType.linkedin:
                        formValues.linkedinLinks.push({ link: profile.value });
                        break;
                    case SocialMediaType.twitter:
                        formValues.twitterLinks.push({ link: profile.value });
                        break;
                    case SocialMediaType.xing:
                        formValues.xingLinks.push({ link: profile.value });
                        break;
                }
            }
        });

    return formValues;
};

export const createDateRangeObjFromFormSchema = (
    dateRange: FormSchemaDateRange | undefined,
): Partial<FormSchemaDateRange> | undefined => {
    if (!dateRange) {
        return undefined;
    }
    const { start, end } = dateRange;

    const result: Partial<FormSchemaDateRange> = {
        start: undefined,
        end: undefined,
    };

    if (start) {
        result.start =
            dateRange.start?.length === 4
                ? moment(dateRange.start, 'YYYY').startOf('year').toISOString()
                : moment(dateRange.start, DATE_FORMATS).toISOString();
    }

    if (end) {
        result.end =
            dateRange.end?.length === 4
                ? moment(dateRange.end, 'YYYY').endOf('year').toISOString()
                : moment(dateRange.end, DATE_FORMATS).toISOString();
    }

    return result;
};

const getSocialMediaProfiles = (
    data:
        | FormSchema['facebookLinks']
        | FormSchema['instagramLinks']
        | FormSchema['twitterLinks']
        | FormSchema['linkedinLinks']
        | FormSchema['xingLinks'],
    type: SocialMediaType,
) => {
    return data.map(({ link }) => ({
        type: type,
        value: link,
    }));
};

const mapFormSchemaDynamicKeywordsToUserInputTarget = (
    keywords?: DynamicKeyword[],
): DynamicFormValue[] =>
    (keywords ?? []).map((keyword) => ({
        id: uniqueId(),
        value: keyword.value,
        status: keyword.included
            ? DynamicFormValueStatus.Confirmed
            : DynamicFormValueStatus.Ignored,
    }));

export const mapFormSchemaToTargetCreationSchema = (
    formData: Partial<FormSchema>,
): TargetCreationRequestBody => {
    const { personalDetails, birthInfo } = formData;

    const dateOfBirth = birthInfo?.dateOfBirth
        ? createDateRangeObjFromFormSchema({
              start: birthInfo.dateOfBirth,
              end: birthInfo.dateOfBirth,
          })
        : undefined;

    const userInputTarget: TargetCreationRequestBody = {
        title: personalDetails?.title,
        gender: personalDetails?.gender,
        firstname: personalDetails?.firstName ?? '',
        lastname: personalDetails?.lastName ?? '',
        middlename: personalDetails?.middleName,
        dateOfBirth:
            dateOfBirth?.start && dateOfBirth?.end
                ? {
                      start: dateOfBirth.start,
                      end: dateOfBirth.end,
                  }
                : undefined,
        placeOfBirth: birthInfo?.placeOfBirth,
        countryOfBirth: birthInfo?.countryOfBirth,

        countryOfResidence: formData.residentialInfo?.countryOfResidence,

        nationalities: (formData?.nationalities ?? [])
            .filter(Boolean)
            .map((item) => item.nationality),
        contact: [],
        jobs: [],
        education: [],
        socialMediaProfiles: [],

        relatedPersons: [],
        websites: [],
        organizations: [],
        topics: mapFormSchemaDynamicKeywordsToUserInputTarget(
            formData.keywords,
        ),
        locations: [],
        nicknames: [],
        note: { value: formData.note?.value },
        description: formData.description,
        skills: formData.skills ?? [],
        hobbies: formData.hobbies ?? [],
        typos: formData.typos ?? [],
    };

    // Map contact Data
    // TODO: DRY wins possible later?
    if (formData?.contactEmails) {
        formData?.contactEmails?.forEach(({ email }) => {
            userInputTarget.contact?.push({
                type: ContactType.email,
                value: email,
            });
        });
    }
    if (formData?.contactPhones) {
        formData?.contactPhones?.forEach(({ phone }) => {
            userInputTarget.contact?.push({
                type: ContactType.phone,
                value: phone,
            });
        });
    }
    if (formData?.contactWebsites) {
        formData?.contactWebsites?.forEach(({ website }) => {
            userInputTarget.contact?.push({
                type: ContactType.website,
                value: website,
            });
        });
    }

    // Map jobs
    userInputTarget.jobs = (formData?.jobs ?? []).map((job) => {
        const { start, end } =
            createDateRangeObjFromFormSchema(job?.jobDate) ?? {};

        const date = start
            ? {
                  start,
                  end: job.isCurrentPosition ? new Date().toISOString() : end,
              }
            : undefined;
        return {
            title: job.jobTitle,
            date,
            selfEmployed: job.selfEmployed,
            company: {
                name: job.companyName,
                website: job.companyWebsite,
                vatNumber: job.companyVatNumber,
                commercialRegisterNumber: job.companyCommercialRegisterNumber,
                country: job.companyCountry,
                city: job.companyCity,
                industry: job.industry,
            },
        };
    });

    // Map education
    userInputTarget.education = (formData?.educationInfo ?? []).map((edu) => {
        const { start, end } =
            createDateRangeObjFromFormSchema(edu?.date) ?? {};

        const date = start
            ? {
                  start,
                  end: edu.isCurrentInstitution
                      ? new Date().toISOString()
                      : end,
              }
            : undefined;

        return {
            title: edu.title,
            type: edu.type,
            date,
            institution: {
                name: edu.institutionName,
                location: edu.institutionLocation,
            },
            level: edu.level,
        };
    });

    userInputTarget.socialMediaProfiles?.push(
        ...getSocialMediaProfiles(
            [...(formData.facebookLinks ?? [])],
            SocialMediaType.facebook,
        ),
        ...getSocialMediaProfiles(
            [...(formData.instagramLinks ?? [])],
            SocialMediaType.instagram,
        ),
        ...getSocialMediaProfiles(
            [...(formData.linkedinLinks ?? [])],
            SocialMediaType.linkedin,
        ),
        ...getSocialMediaProfiles(
            [...(formData.twitterLinks ?? [])],
            SocialMediaType.twitter,
        ),
        ...getSocialMediaProfiles(
            [...(formData.xingLinks ?? [])],
            SocialMediaType.xing,
        ),
    );

    return userInputTarget;
};

export const fallbackUrlRegex =
    /(https?:\/\/)?(www\.)?[-\\p{L}0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-\\p{L}0-9@:%_+.~#()?&/=]*)/u;

export const getRegexForSocialMedia = (type: SocialMediaType): RegExp => {
    switch (type) {
        case SocialMediaType.facebook:
            return facebookIdRegExp;
        case SocialMediaType.instagram:
            return instagramIdRegExp;
        case SocialMediaType.linkedin:
            return linkedinIdRegExp;
        case SocialMediaType.twitter:
            return twitterIdRegExp;
        case SocialMediaType.xing:
            return xingIdRegExp;
        default:
            // in case of not handled SocialMediaType at least validate that it's a valid URL
            return fallbackUrlRegex;
    }
};

export const getDobDateRange = (
    dateOfBirth: string | undefined,
): { start: DateStr; end: DateStr } | undefined => {
    const { start, end } = extractDateRangeObjForFormSchema(dateOfBirth);
    if (!start) {
        return undefined;
    }
    try {
        const dobStart = toDateStr(
            moment(start, DATE_FORMATS).format('YYYY-MM-DD'),
        );

        const dobEnd = end
            ? toDateStr(moment(end, DATE_FORMATS).format('YYYY-MM-DD'))
            : dobStart;

        return {
            start: dobStart,
            end: dobEnd,
        };
    } catch (error) {
        // toDateStr can throw an exception
        return undefined;
    }
};
export const mapUserInputTargetToTargetCreationSchema = (
    target: UserInputTarget,
): TargetCreationRequestBody => {
    return mapFormSchemaToTargetCreationSchema(
        mapUserInputTargetToFormSchema({
            ...target,
            dateOfBirth: getDobDateRange(target.dateOfBirth),
        }),
    );
};
