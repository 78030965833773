import { FC } from 'react';
import {
    generatePath,
    Redirect,
    useHistory,
    useParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LoadingSpinner } from '_atoms';
import { useCreateTargetMutation, useTargetInputQuery } from '_queries';
import { useMutationErrors } from '../../../hooks/useMutationErrors';
import { routes } from '../../../routes';
import { TargetForm } from '../../targets/TargetForm';
import { Alert } from '_molecules';
import { useTargetCreationStateStore } from '../../../state/useStore';
import { mapUserInputTargetToFormSchema } from '../../targets/TargetNew/wizard/utils';
import { ProcessTarget } from '@indicium/common';

export const InputData: FC = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const { caseId, targetId } =
        useParams<{
            caseId: string;
            targetId: string;
        }>();

    const { data: targetInput, isLoading: isTargetInputLoading } =
        useTargetInputQuery(caseId, targetId);

    const { data: target, ...createTargetMutation } = useCreateTargetMutation();

    const { errors: apiErrors } = useMutationErrors({
        mutations: { createTargetMutation },
    });

    const { setTargetFormData } = useTargetCreationStateStore();

    const redirect = (values: unknown) => {
        setTargetFormData(caseId, {
            formData: mapUserInputTargetToFormSchema(
                (values ?? {}) as ProcessTarget,
            ),
            cvImages: [],
        });
        history.push({
            pathname: generatePath(routes.newTargetData.path, { caseId }),
            state: { data: values },
        });
    };

    if (createTargetMutation.isSuccess) {
        return (
            <Redirect
                to={generatePath(routes.targetShow.path, {
                    caseId,
                    targetId: target?.id,
                })}
            />
        );
    }

    if (createTargetMutation.isError) {
        console.error('error in createTarget: ', createTargetMutation.error);
    }

    return (
        <div className="container-fluid p-9 xl:max-w-max-content">
            <Alert alertType="disclamer" message={t('inputDataDisclamer')} />
            <TargetForm
                targetInput={targetInput}
                isSubmitting={createTargetMutation.isLoading}
                apiErrors={apiErrors}
                redirect={redirect}
                disabled={true}
            />
            {isTargetInputLoading && (
                <div className="z-40 inset-0 absolute bg-black/50 flex justify-center items-center text-neutral-400 pointer-events-none">
                    <LoadingSpinner
                        message={<span className="invisible">...</span>}
                    />
                </div>
            )}
        </div>
    );
};
