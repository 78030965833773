import {
    Paragraph,
    TooltipContentV2,
    TooltipTriggerV2,
    TooltipV2,
} from '_atoms';
import { SuspicionItemStatus } from '_enums';
import React, { useMemo } from 'react';
import { ReactComponent as ExclamationIcon } from '_assets/icons/exclamation.svg';
import { ReactComponent as CheckIcon } from '_assets/icons/check.svg';
import { ReactComponent as CrossIcon } from '_assets/icons/cross.svg';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface SuspicionStatusIconProps {
    status: SuspicionItemStatus;
    className?: string;
}

const commonIconStyle = 'w-4 h-4 flex justify-center items-center';

export const SuspicionStatusIcon = (
    props: SuspicionStatusIconProps,
): React.ReactElement => {
    const { status, className } = props;
    const { t } = useTranslation();

    const sharedIconClassNames = classNames(commonIconStyle, className);

    const statusIcon = useMemo(() => {
        switch (status) {
            case SuspicionItemStatus.Pending:
                return (
                    <ExclamationIcon
                        className={classNames(
                            sharedIconClassNames,
                            'text-neutral-500 rotate-180',
                        )}
                    />
                );
            case SuspicionItemStatus.NoProblem:
            case SuspicionItemStatus.WrongTarget:
                return (
                    <CrossIcon
                        className={classNames(
                            sharedIconClassNames,
                            'text-error-1',
                        )}
                    />
                );
            case SuspicionItemStatus.Problem:
                return (
                    <CheckIcon
                        className={classNames(
                            sharedIconClassNames,
                            'text-success-1',
                        )}
                    />
                );
        }
    }, [sharedIconClassNames, status]);

    const tooltipStatusText = t(`suspicionItemStatus.${status}`);

    const tooltipStatusExplanation = useMemo(() => {
        switch (status) {
            case SuspicionItemStatus.Pending:
                return t('riskAnalysis.editSuspicions.suspicionPending');
            case SuspicionItemStatus.NoProblem:
            case SuspicionItemStatus.WrongTarget:
                return t('riskAnalysis.editSuspicions.suspicionDeleted');
            case SuspicionItemStatus.Problem:
                return t('riskAnalysis.editSuspicions.suspicionConfirmed');
        }
    }, [t, status]);

    return (
        <TooltipV2 openDelay={200} placement="top">
            <TooltipContentV2 className="max-w-72 z-20">
                <Paragraph size="label" color="white">
                    {`${tooltipStatusText}: ${tooltipStatusExplanation}`}
                </Paragraph>
            </TooltipContentV2>
            <TooltipTriggerV2>{statusIcon}</TooltipTriggerV2>
        </TooltipV2>
    );
};
