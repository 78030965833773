import axios from 'axios';
import { useMutation, UseMutationResult } from 'react-query';
import { baseURL } from '../../services/nestApiService';
import { ChecklistTemplate } from '../../types/ChecklistTemplate';

export const createCecklistTemplate = async (
    data: Omit<ChecklistTemplate, 'id'>,
): Promise<ChecklistTemplate> => {
    return (await axios.post(`${baseURL}/checklists/templates`, data)).data;
};

export const useCreateChecklistTemplateMutation = ({
    onSuccess,
    onError,
}: {
    onSuccess?: (data: ChecklistTemplate) => void;
    onError?: () => void;
}): UseMutationResult<
    ChecklistTemplate,
    string,
    Omit<ChecklistTemplate, 'id'>
> => {
    return useMutation(createCecklistTemplate, {
        onSuccess,
        onError,
    });
};
